import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { useHistory,useLocation } from "react-router-dom";
import swal from 'sweetalert';
import { Helmet } from 'react-helmet'
import BaseUrl from '../BaseUrl';
import {decode as base64_decode, encode as base64_encode} from 'base-64'
import userIcon from "../assest/faregularidbadge5826-0c9.svg"
import passIcon from "../assest/mdipassword2706-4ypz.svg"

import './login.css'

const VerifyForgotPass = (props) => {
  const [otp, setOtp] = useState("")
  const location = useLocation();
  const history = useHistory();
 console.log("location", location)

var companyId =location.state.state.data.sendOtp.company_Id

              


 // console.log("componyId", componyId)

  const verifyForgotPasss = (e) => {
    e.preventDefault();
    let data = { otp,companyId}
    fetch(BaseUrl()+"verify/otp/forgot/pass", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((result) => {
      result.json().then((response) => {
          console.log("response", response)

        const ResponseCode = response.ResponseCode;
        if(ResponseCode === 200){
          history.push('/change-password', {
          state: {
            data: response
          }
        })


        }
        else{
          swal(response.ResponseMessage);
        }
      })
    })
  }
  
  return (
    <div className="login-container">
      <Helmet>
        <title>FIRETRACK</title>
        <meta property="og:title" content="Shrill Frosty Moose" />
      </Helmet>
      <div className="login-signin-main-panel">
        <div className="login-transparent-box">
          <form onSubmit={verifyForgotPasss}>
          <span className="login-welcome-text-otp-title">Verifizierung</span>
          <span className="login-welcome-text-otp">Code gesendet! Bitte füge den Code, welchen wir dir auf deine Handynummer gesendet haben unten ein.</span>

          {/*<span className="login-welcome-text">Willkommen</span>*/}
          {/*<span className="login-little-header-text">Sign in Your Account</span>*/}
          <div className="login-company-id">
            <img
              alt="faregularidbadge5826"
              src={userIcon}
              className="login-icon1"
            />
            <input
              type="text"
              required
              placeholder="Code"
              className="login-textinput input"
              value={otp} 
              onChange={(e) => { setOtp(e.target.value) }}
              
            />
          </div>
       {/*   <div className="login-pass-word">
           <img
              alt="mdipassword2706"
              src={passIcon}
              className="login-icon2"
            />
           <input
              type="password"
              required
              autoFocus
              placeholder="Passwort"
              className="login-textinput1 input"
              value={password} 
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </div>*/}
            <button className="login-signin-button">
                <p className="text-center  login-text">Anmelden</p>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default VerifyForgotPass
