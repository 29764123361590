import React from 'react'

import PropTypes from 'prop-types'

import './add-user-management.css'
import {Modal} from "react-bootstrap"

const AddUserManagement = (props) => {
  
         
  return (
    <>
    <Modal
          {...props}
          >
    <div className={`add-user-management-container ${props.rootClassName} `}>
      <div className="add-user-management-close-button"  onClick={props.onHide}>
        <svg viewBox="0 0 1024 1024" className="add-user-management-icon">
          <path
            d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
            className=""
          ></path>
        </svg>
      </div>
      <div className="add-user-management-container1">
        <div className="add-user-management-comapany-details">
          <span className="add-user-management-text">Add User</span>
        </div>
        <div className="add-user-management-input-feilds">
          <input
            type="text"
            required
            autoFocus
            placeholder="Name"
            className="input"
          />

          <input
            type="text"
            required
            autoFocus
            placeholder="Email"
            className="add-user-management-textinput1 input"
          />
          
          <input
            type="text"
            required
            autoFocus
            placeholder="Address"
            className="add-user-management-textinput2 input"
          />
          <input
            type="text"
            required
            autoFocus
            placeholder="Phone Number"
            className="add-user-management-textinput3 input"
          />
        </div>
        <div className="add-user-management-done-button">
          <div className="add-user-management-container2">
            <span className="add-user-management-text1">Done</span>
          </div>
        </div>
      </div>
    </div>
    </Modal>
   </>
  )
}

AddUserManagement.defaultProps = {
  rootClassName: '',
}

AddUserManagement.propTypes = {
  rootClassName: PropTypes.string,
}

export default AddUserManagement
