 
//  const  BaseUrl = () => {
//     //return "https://api-testenv.firetrack.aero/";
//      return "http://localhost:10004/";
    
//    };
  
//   export default BaseUrl


const BaseUrl = () => {
  if(process.env.REACT_APP_ENV === 'production'){
    console.log("Setting up production api connection")
	  return "https://api.firetrack.aero/"
  }else {
    console.log("Setting up test api connection")
	  return "https://api-testenv.firetrack.aero/"
  }
};

export default BaseUrl;
