import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './help-center-pop-up.css';
import BaseUrl from '../BaseUrl';
import axios from 'axios';
import auth from '../Auth';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";

const HelpCenterPopUp = (props) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [query, setQuery] = useState('');
  const { i18n, t } = useTranslation();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const token = await localStorage.getItem('authorization');
    var auth = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };

    const url = BaseUrl() + 'user/alldata';
    try {
      const res = await axios.get(url, auth);
      if (res.data.ResponseCode === 200) {
        setEmail(res.data.userFind.email); // Store the email address in the email state variable
      } else {
        swal(res.data.ResponseMessage, {
          buttons: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitQuery = async (e) => {
    e.preventDefault();
    console.log('click');
    const url = BaseUrl() + 'add/helpcenter/request';
    const data = {
      title: subject,
      group: 'User FIRETRACK',
      customer: email,
      article: {
        subject: subject,
        body: query,
        type: 'note',
        internal: false,
      },
    };

    try {
      const response = await axios.post(url, data);
      console.log('click');
      console.log('response', response);
      if (response.data.ResponseCode === 200) {
        props.onHide();
        swal(response.data.ResponseMessage);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <Modal
          {...props}
         >
    <div className={`help-center-pop-up-container ${props.rootClassName} `}>
      <div className="help-center-pop-up-container1">
        <div className="help-center-pop-up-close-button" onClick={props.onHide}>
          <svg viewBox="0 0 1024 1024" className="help-center-pop-up-icon">
            <path
              d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              className=""
            ></path>
          </svg>
        </div>
        <div className="help-center-pop-up-container2">
          <div className="help-center-pop-up-comapany-details">
            <span className="help-center-pop-up-text">{t("support.title")}</span>
          </div>
          <form o mathod="post" onSubmit={e=>submitQuery(e)}>

          <div className="help-center-pop-up-input-feilds">

            <input
              type="subject"
              required
              autoFocus
              placeholder={t("support.subject")}
              className="help-center-pop-up-textinput input"
              value={subject}
                onChange={(e) => {
                setSubject(e.target.value);
                }}
            />
            
            <textarea
              placeholder={t("support.message")}
              className="help-center-pop-up-textarea textarea"
              value={query}
              required
              onChange={(e) => {
              setQuery(e.target.value);
              }}
            ></textarea>

          </div>
          <button className="help-center-pop-up-done-button" >
            <div className="help-center-pop-up-container3">
              <span className="help-center-pop-up-text2">{t("support.submit")}</span>
            </div>
          </button>
          </form>

          
        </div>
      </div>
    </div>

    </Modal>
    </>
  )
}

HelpCenterPopUp.defaultProps = {
  text: 'Text',
  rootClassName: '',
  textarea_placeholder: 'placeholder',
  'Help Center PopUp': () => {},
}

HelpCenterPopUp.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  textarea_placeholder: PropTypes.string,
  'Help Center PopUp': PropTypes.func,
}

export default HelpCenterPopUp
