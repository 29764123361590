import React,{useState} from 'react'

import PropTypes from 'prop-types'

import './profile-pop-up.css'
import {Modal} from "react-bootstrap"


const ProfilePopUp = (props) => {
  return (
    <>
       <Modal
          {...props}
          >
    <div className={`profile-pop-up-container ${props.rootClassName} `}>
      <div className="profile-pop-up-container1">
        <div className="profile-pop-up-close-button" onClick={props.onHide}>
          <svg viewBox="0 0 1024 1024" className="profile-pop-up-icon">
            <path
              d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              className=""
            ></path>
          </svg>
        </div>
        <div className="profile-pop-up-container2">
          <div className="profile-pop-up-comapany-details">
            <span className="profile-pop-up-text">Company Details</span>
          </div>
          <div className="profile-pop-up-input-feilds">
            <input
              type="text"
              required
              autoFocus
              placeholder="Name"
              className="input"
            />
            <input
              type="text"
              required
              autoFocus
              placeholder="Email"
              className="profile-pop-up-textinput1 input"
            />
            <input
              type="text"
              required
              autoFocus
              placeholder="Address"
              className="profile-pop-up-textinput2 input"
            />
            <input
              type="text"
              required
              autoFocus
              placeholder="Phone Number"
              className="profile-pop-up-textinput3 input"
            />
          </div>
          <div className="profile-pop-up-done-button">
            <div className="profile-pop-up-container3">
              <span className="profile-pop-up-text1">Done</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Modal>
    </>
  )
}

ProfilePopUp.defaultProps = {
  rootClassName: '',
  text1: 'Text',
  text: 'Text',
  textinput_placeholder: 'placeholder',
}

ProfilePopUp.propTypes = {
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
  textinput_placeholder: PropTypes.string,
}

export default ProfilePopUp
