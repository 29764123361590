import React from 'react'

import PropTypes from 'prop-types'

import './helicopter-icon-stack-hover.css'

const HelicopterIconStackHover = (props) => {
  return (
    <div
      className={`helicopter-icon-stack-hover-container ${props.rootClassName} `}
    >
      <div className="helicopter-icon-stack-hover-container01">
        <div className="helicopter-icon-stack-hover-container02">
          <span className="helicopter-icon-stack-hover-text">HB-ZJD</span>
          <div className="helicopter-icon-stack-hover-container03">
            <span className="helicopter-icon-stack-hover-text01">30</span>
            <span className="helicopter-icon-stack-hover-text02">Min</span>
          </div>
        </div>
        <div className="helicopter-icon-stack-hover-container04">
          <span className="helicopter-icon-stack-hover-text03">HB-ZJD</span>
          <div className="helicopter-icon-stack-hover-container05">
            <span className="helicopter-icon-stack-hover-text04">2</span>
            <span className="helicopter-icon-stack-hover-text05">Std</span>
          </div>
        </div>
        <div className="helicopter-icon-stack-hover-container06">
          <span className="helicopter-icon-stack-hover-text06">HB-ZJD</span>
          <div className="helicopter-icon-stack-hover-container07">
            <span className="helicopter-icon-stack-hover-text07">&gt;4</span>
            <span className="helicopter-icon-stack-hover-text08">Std</span>
          </div>
        </div>
        <div className="helicopter-icon-stack-hover-container08">
          <span className="helicopter-icon-stack-hover-text09">HB-ZJD</span>
          <div className="helicopter-icon-stack-hover-container09">
            <span className="helicopter-icon-stack-hover-text10">N/A</span>
            <span className="helicopter-icon-stack-hover-text11">HB-ZJD</span>
          </div>
        </div>
        <svg
          viewBox="0 0 585.1428571428571 1024"
          className="helicopter-icon-stack-hover-icon"
        >
          <path
            d="M585.143 402.286c0 9.714-4 18.857-10.857 25.714l-256 256c-6.857 6.857-16 10.857-25.714 10.857s-18.857-4-25.714-10.857l-256-256c-6.857-6.857-10.857-16-10.857-25.714 0-20 16.571-36.571 36.571-36.571h512c20 0 36.571 16.571 36.571 36.571z"
            className=""
          ></path>
        </svg>
      </div>
    </div>
  )
}

HelicopterIconStackHover.defaultProps = {
  rootClassName: '',
}

HelicopterIconStackHover.propTypes = {
  rootClassName: PropTypes.string,
}

export default HelicopterIconStackHover
