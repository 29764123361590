import React, { useState,useRef,useEffect  } from 'react'
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'
import './add-button-and-search.css'
import BaseUrl from '../BaseUrl'
import axios from 'axios'
// import auth from '../Auth'
import swal from 'sweetalert'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useOnClickOutside from "./use-onclick-outside";
import Spinner from 'react-bootstrap/Spinner'



const AddButtonAndSearch = (props) => {

  const ref = useRef();

  const [ShowAddUser, setShowAddUser] = useState(false)
  const { i18n, t } = useTranslation();
  const[name,setName] = useState('')
  const[email,setEmail] = useState('')
  const[password,setPassword] = useState('')
  const[mobile_number,setMobileNumber] = useState('')
  const[company_Id,setComponyId] = useState("")
  const[address,setAddress] =useState('')
  const[role,setRole] = useState();
  // useOnClickOutside(ref, () => setShowAddUser(false));

    const handleNumChange = event => {
    const limit = 10;
    setMobileNumber(event.target.value.slice(0, limit));
  };
   const token = localStorage.getItem("authorization")
  var auth 

  const addUser = async(e) => {
      const token = await localStorage.getItem("authorization")
      console.log("token",token)
      var auth = {
  headers: {
    authorization: `bearer ${token}`,
  },
};
    e.preventDefault();

    const url = BaseUrl() + "user/add";
    const data = {
      name:name,
      email:email,
      password:password,
      mobile_number:mobile_number,
      company_Id  :company_Id,
      role:role,
      address:address
    }
    try{
      const res = await axios.post(url,data,auth)
      if(res.data.ResponseCode == 200){
        console.log("res",res)
        swal(t(`${res.data.ResponseMessage}`), {
          icon: "success",
        }).then(function() {
          setShowAddUser(false)
          setName("");
          setEmail("");
          setPassword("");
          setMobileNumber("");
          setComponyId("");
          setAddress("");
          setRole("");
          window.location.reload(false);
        });
        
      }else{
        swal(t(`${res.data.ResponseMessage}`),{
      dangerMode: true,        })
      }

    }catch(error){
      console.log(error)
      swal(error)
    }
  }
 useEffect(() => {
 var auth = localStorage.getItem("authorization")  
  }, []);

  return (
    <div
    ref={ref} 
      className={`add-button-and-search-add-button-and-search ${props.rootClassName} `}
        >
      <div
        onClick={() => setShowAddUser(true)}
        className="add-button-and-search-add-button"
         >
        <span
          onClick={() => setShowAddUser(true)}
          className="add-button-and-search-text"
          >
          {t("user.addUser")}
        </span>
      </div>

    {/*  <div className="add-button-and-search-search-box">
        <svg viewBox="0 0 1024 1024" className="add-button-and-search-icon">
          <path
            d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"
            className=""
          ></path>
        </svg>
        <input
          type="text"
          placeholder={props.textinput_placeholder}
          className="add-button-and-search-textinput input"
        />
      </div>*/}

       

      {ShowAddUser &&
        <div className='popup-start show__popup'>
         <div ref={ref} >
         <form mathod="post" onSubmit={e=>addUser(e)}>
         <div className="add-button-and-search-container">
          <div
            onClick={() => setShowAddUser(false)}
            className="add-button-and-search-close-button"
          >
            <svg
              viewBox="0 0 1024 1024"
              onClick={() => setShowAddUser(false)}
              className="add-button-and-search-icon2"
            >
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                className=""
              ></path>
            </svg>
          </div>


          <div className="add-button-and-search-container1">
            <div className="add-button-and-search-comapany-details">
              <span className="add-button-and-search-text1"> {t("user.addUser")}</span>
            </div>

            <div className="add-button-and-search-input-feilds">
              <input
                type="text"
                required
                autoFocus
                placeholder={t("user.name")}
                className="input"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              <input
                type="email"
                required
                autoFocus
                placeholder="Email"
                className="add-button-and-search-textinput2 input"
                value={email}
                onChange={(e)=> {
                  setEmail(e.target.value)
                }}
              />
              <input
                type="text"
                required
                autoFocus
                placeholder={t("user.address")}
                className="add-button-and-search-textinput3 input"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value)
                }}
              />
              <input
                type="number"
                required
                autoFocus
                placeholder={t("user.phoneNumber")}
                className="add-button-and-search-textinput4 input"
                value={mobile_number}
                onChange={handleNumChange}
                oninvalid="this.setCustomValidity('Selected quantity is more than stock status')"
              />
              <input
                type="text"
                required
                autoFocus
                placeholder={t("user.userName")}
                className="add-button-and-search-textinput5 input"
                value={company_Id}
                onChange={(e) => {
                  setComponyId(e.target.value)
                }}
              />
              <input
                type="password"
                required
                autoFocus
                placeholder={t("user.password")}
                className="add-button-and-search-textinput6 input"
                value={password}
                onChange={(e) => {

                  setPassword(e.target.value)
                }}
              />
              <select className="selection-class" 
              onChange={(e) => {
                setRole(e.target.value)
              }}
              >
                 <option value="" className="">
                  Select
                </option>
                
                <option value="admin" className="">
                  Admin
                </option>
                <option value="user" className="">
                  User
                </option>
                  <option value="visitor" className="">
                  Visitor
                </option>
              </select>
            </div>
            
        <div className="add-button-and-search-done-button" >
              <div className="add-button-and-search-container2">
                <button className="add-button-and-search-text2" >{t("user.add")}</button>
              </div>
            </div>

            
          </div>


        </div>

        </form>



</div>
</div>
 }

      </div>
  )
}

AddButtonAndSearch.defaultProps = {
  text: "Text",
  textinput_placeholder: 'Search',
  rootClassName: '',
}

AddButtonAndSearch.propTypes = {
  text: PropTypes.string,
  textinput_placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default AddButtonAndSearch
