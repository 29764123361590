import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route ,Outlet,Redirect} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import ManageHelicopter from './views/manage-helicopter'
import Login from './views/login'
import OtpVerify from './views/OtpVerify'
import UserManagement from './views/user-management'
import PrivateRouter from './privateRouter'
import UserLogged from './userLoggdin'
import { About } from './views/about'
import { Impressum } from './views/impressum'
import { AddAboutUS } from './views/addAboutUs'
import { AddImpressum } from './views/addImpressum'
import SendOtp  from './views/SendOtpForgotPassword'
import VerifyForgotPass  from './views/VerifyOtpForgotPass'
import ChangePassword  from './views/ChangePassword'
import "./i18n";




const App = () => {
  return (

    <Router basename='/'>
      <div>
         {/*<UserLogged/> */}
        <Route component={Login} exact path="/" />
        <Route component={OtpVerify} exact path="/otp-verify" />
        <PrivateRouter component={Home} exact path="/home" />
        <PrivateRouter component={AddAboutUS} exact path="/add-about" />
        <PrivateRouter component={AddImpressum} exact path="/add-landing" />
        <PrivateRouter component={ManageHelicopter} exact path="/manage-helicopter" />
        <Route component={About} exact path="/about-us" />
        <Route component={SendOtp} exact path="/forgot-pass" />
        <Route component={VerifyForgotPass} exact path="/forgot-pass-verify" />
        <Route component={ChangePassword} exact path="/change-password" />
        <Route component={Impressum} exact path="/impressum" />
        <PrivateRouter exact path="/user-management" component={UserManagement}/>
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
