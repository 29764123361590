import React from 'react'
import {Route,Redirect} from 'react-router-dom'

 
 const privateRouter=({component:Component,...rest})=>{
     return <Route {...rest} component={(props)=>{
          const adminToken = window.localStorage.getItem("authorization");
          
           if(adminToken){
              return <Component {...props} />
           }else{ 
               return <Redirect to={"/"} />
          }
     }} />
} 

export default privateRouter