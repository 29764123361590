import React, { useState,useEffect } from 'react'
import Navbar from '../components/navbar'
import DataTable from "react-data-table-component";
import BaseUrl from '../BaseUrl';
import auth from '../Auth';
import axios from 'axios';
import { Col, Container, Row, Button } from 'react-bootstrap';


/**
* @author
* @function Impressum
**/
export const AddImpressum = () => {
    const [addContent,setContent] = useState('')

    const addImpressumm = async(e) =>  {
    e.preventDefault();
    console.log("click")
    const url = BaseUrl()+"add/content"
    const data = {
      content:addContent
    }
   try{
    const response = await axios.post(url,data)
    console.log("response",response)
    if(response.data.ResponseCode === 200){
      swal(response.data.ResponseMessage,{
      	icon:"success",
      	button:false
      })
    }else{
      console.log(response);
    }

   }catch (error){
    console.log(error)
   }
  }
  
  const getImpressum = async () => {
      const url = BaseUrl()+"content"
      try {
        const res = await axios.get(url, auth)
        console.log("res", res);
        setContent(res.data.content.content);
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      getImpressum();
    }, []);
 
  return(
    <>
    <div>
    <Navbar/>
    <br/>
   
    <Container>
        <Col sm="12">
              <h1 className="mb-5" style={{color:"rgb(155,36,35)"}} align="center">Impressum</h1>
              <div className="shadow-lg p-3 mb-5 bg-body rounded mx-auto" style={{color:"rgb(155,36,35)"}} align="center" >
                
                 <textarea 
                 align="center"
                 rows="10"
                  type="text"
                  required
                  style={{marginLeft:200}}
                  autoFocus
                  placeholder="Content"
                  className=" mx-auto  textarea"
                  value={addContent}
                  onChange={(e) => {
                  setContent(e.target.value);
                  }}
                />

            <div className="add-helicopter-container-submit-button" >
              <div className="add-helicopter-container-done-button">
                <div className="add-helicopter-container-container5">
                  <button onClick={addImpressumm} className="add-helicopter-container-text3">Submit</button>
                </div>
              </div>
            </div>
            </div>
         </Col>
         </Container>
        </div>
      </>
      )
  }