import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { Helmet } from 'react-helmet'
import BaseUrl from '../BaseUrl';
import {decode as base64_decode, encode as base64_encode} from 'base-64'
import userIcon from "../assest/faregularidbadge5826-0c9.svg"
import passIcon from "../assest/mdipassword2706-4ypz.svg"
import './login.css'
import { useTranslation } from "react-i18next";




const Login = (req) => {
  const [companyId, setCompanyId] = useState("")
  const [password, setPassword] = useState("")
  const history = useHistory();
  const { i18n, t } = useTranslation();


  const loginUser = async(e) => {
    e.preventDefault();
    let data = { companyId:companyId, password:password }
    await fetch(BaseUrl()+"send/otp", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((result) => {
      result.json().then(async(response) => {
        console.log("response", response)
        const ResponseCode = response.ResponseCode;
        if(response.sendOtp){
          history.push('/otp-verify', {
          state: {
            data: response
          }
        })
        console.log("response", response)
        } 
        if(response.data) {
          localStorage.setItem("authorization", response.data.token);
          localStorage.setItem("role",base64_encode( response.data.user.role));
          localStorage.setItem("userId",base64_encode( response.data.user.id ));
          localStorage.setItem("language", response.data.user.language );

          history.push("/home")
          console.log("response", response)
          if(response.data.user.language){
            console.log("Setting up language from db info: ", response.data.user.language)
            i18n.changeLanguage(response.data.user.language);

          }
        }
        if(response.ResponseCode == 500){
          swal(response.ResponseMessage,{
            button:false
          });
        }
      })
    })
  }

  return (
    <div className="login-container">
      <Helmet>
        <title>FIRETRACK</title>
        <meta property="og:title" content="Shrill Frosty Moose" />
      </Helmet>
      <div className="login-signin-main-panel">
        <div className="login-transparent-box">
          <form onSubmit={e => loginUser(e)}>
          <span className="login-welcome-text">Willkommen</span>
          {/*<span className="login-little-header-text">Sign in Your Account</span>*/}
          <div className="login-company-id">
            <img
              alt="faregularidbadge5826"
              src={userIcon}
              className="login-icon1"
            />
            <input
              type="text"
              required
              placeholder="Benutzername"
              className="login-textinput input"
              value={companyId} 
              onChange={(e) => { setCompanyId(e.target.value) }}
              
            />
          </div>
          <div className="login-pass-word">
           <img
              alt="mdipassword2706"
              src={passIcon}
              className="login-icon2"
            />
           <input
              type="password"
              required
              autoFocus
              placeholder="Passwort"
              className="login-textinput1 input"
              value={password} 
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </div>
          <Link className="forgot-pass text-white"   to="forgot-pass">Passwort vergessen</Link>
            <button className="login-signin-button">
                <p className="text-center login-text">Code senden</p>
            </button>

          </form>
        <a href="about-us" className='about-us text-white'>Über uns</a>
        <a href="impressum" className='about-us ml-5 text-white'>Impressum</a>

        </div>
      </div>
    </div>
  )
}

export default Login
