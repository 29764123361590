import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { Helmet } from 'react-helmet'
import BaseUrl from '../BaseUrl';
import {decode as base64_decode, encode as base64_encode} from 'base-64'
import userIcon from "../assest/faregularidbadge5826-0c9.svg"
import passIcon from "../assest/mdipassword2706-4ypz.svg"
import './login.css'




const SendOtp = (req) => {
  const [companyId, setCompanyId] = useState("")
  const history = useHistory();

  const SendOtpforgotPass = async(e) => {
    e.preventDefault();
    let data = { companyId:companyId }
    await fetch(BaseUrl()+"send/otp/forgot/pass", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((result) => {
      result.json().then(async(response) => {
        console.log("response", response)
        const ResponseCode = response.ResponseCode;
        if(ResponseCode == 200){
          history.push('/forgot-pass-verify', {
          state: {
            data: response
          }
        })
        console.log("response", response)
        } 
        if(response.ResponseCode == 500){
          swal(response.ResponseMessage,{
            button:false
          });
        }
      })
    })
  }

  return (
    <div className="login-container">
      <Helmet>
        <title>FIRETRACK</title>
        <meta property="og:title" content="Shrill Frosty Moose" />
      </Helmet>
      <div className="login-signin-main-panel">
        <div className="login-transparent-box">
          <form onSubmit={e => SendOtpforgotPass(e)}>
          <span className="login-welcome-text">Willkommen</span>
          {/*<span className="login-little-header-text">Sign in Your Account</span>*/}
          <div className="login-company-id">
            <img
              alt="faregularidbadge5826"
              src={userIcon}
              className="login-icon1"
            />
            <input
              type="text"
              required
              placeholder="Benutzername"
              className="login-textinput input"
              value={companyId} 
              onChange={(e) => { setCompanyId(e.target.value) }}
              
            />
          </div>
          <div className="login-pass-word">
          
          </div>
            <button className="login-signin-button">
                <p className="text-center  login-text">Code senden</p>
            </button>

          </form>
        <a href="about-us" className='about-us text-white'>Über uns</a>
        <a href="impressum" className='about-us ml-5 text-white'>Impressum</a>
      
        </div>
      </div>
    </div>
  )
}

export default SendOtp
