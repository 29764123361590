import React, { useState, useEffect } from "react";

import Navbar from "../components/navbar";
import AddHelicopterContainer from "../components/add-helicopter-container";
import HeliCardData from "../components/Data/heliCardData";

import "./manage-helicopter.css";

const ManageHelicopter = (props) => {
  const [isVisible, setIsVisible] = useState(false)

  const  handleClose = () => setIsVisible(false);
  const handleShow = () => setIsVisible(true);



  return (
    <>
      <div onClick={handleClose} >
        <Navbar />
      </div>
      <div className="manage-helicopter-container overflow-hidden">
        {/* <div className="manage-helicopter-container1">
          <div className="manage-helicopter-container2">
            <span className="manage-helicopter-text11" onClick={handleShow}>
              Add Helicopter
            </span>
          </div>
        </div> */}
        <AddHelicopterContainer/>
        <div className="manage-helicopter-container3" >
          <HeliCardData />
        </div>
      </div>
    </>
  );
};

export default ManageHelicopter;
