import I18n from "i18next";
import { initReactI18next } from "react-i18next";
const language = localStorage.getItem("language") ? localStorage.getItem("language") : "de"
I18n.use(initReactI18next);
I18n.use(initReactI18next).init({
    lng: language,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                //buttons and placeholders
                "navbar.map": 'Map',
                "navbar.notifications": 'Notifications',
                "navbar.account": "Account",
                "navbar.account.save": "Save",
                "navbar.account.placeholder.name": "Name",
                "navbar.account.placeholder.email": "Email",
                "navbar.account.placeholder.address": "Address",
                "navbar.account.placeholder.phonenumber": "Phone Number",
                "navbar.manageHeli": "Manage Helicopters",
                "navbar.support": "Support",
                "navbar.manageUsers": "Manage Users",
                "navbar.logout": "Log Out",

                "helicopter.ready": "Ready in",
                "helicopter.operator": "Operator",
                "helicopter.number": "Number",
                "helicopter.watertank": "Equipment",
                "helicopter.capacity": "Capacity",
                "helicopter.liveURL": "Live-View",
                "helicopter.visibility": "Visbility", 

                "helicopter.type": "Type",
                "helicopter.addHelicopter": "Add Helicopter",
                "helicopter.addHelicopter.registration": "Registration",
                "helicopter.addHelicopter.callsign": "Callsign",
                "helicopter.addHelicopter.submit": "Submit",
                "helicopter.edit": "Edit",
                "helicopter.delete": "Delete",
                "helicopter.delete.warning.title": "Delete Helicopter",
                "helicopter.delete.warning.text": "This will permanently delete a helicopter. Are you sure you want to continue?",
                "helicopter.update": "Update Helicopter",
                "helicopter.update.success": "Successfully Updated",

                "support.title": "Support",
                "support.subject": "Subject",
                "support.message": "Your Message",
                "support.submit": "Submit",

                "user.addUser": 'Create User',
                "user.updateUser": 'Update User',
                "user.edit": "Edit",

                "user.action": 'Action',
                "user.name": 'Name',
                "user.role": 'Role',
                "user.address": 'Address',
                "user.phoneNumber": 'Phone Number',
                "user.userName": 'Username',
                "user.password": 'Password',
                "user.add": "Create",
                "user.update": "Update",
                "user.delete": "Delete",
                "user.delete.warning.title": "Delete User",
                "user.delete.warning.text": "This will permanently delete this user. Are you sure you want to continue?",
                "user.delete.warning.success": "Successfully Deleted",

                //error messages
                "user.add.error.default": "Something went wrong. User was not successfully added.",
                "user.add.error.mobile": "Please enter a valid phone number.",
                "user.add.error.email": "Please enter a valid email.",
                "user.add.error.company": "Please enter a valid company ID.",
                "user.add.error.company.exists": "This company ID is already registered.",
                "user.add.error.email.exists": "This email is already registered.",
                "user.add.success": "User added successfully.",

                "user.update.error.invalidToken": "Invalid token",
                "user.update.error.notFound": "User not found",
                "user.update.success": "User updated successfully.",

                "user.delete.error": "User was not successfully deleted.",
                "user.delete.success": "User was successfully deleted.",

                "helicopter.add.success": "Helicopter was successfully added.",
                "helicopter.add.error": "Helicopter was not successfully added.",

                "helicopter.update.success": "Helicopter was successfully updated.",
                "helicopter.update.error": "Helicopter was not successfully updated.",

                "helicopter.status.update.success": "Helicopter status was successfully updated.",
                "helicopter.visibility.update.success": "Helicopter visibility was successfully updated.",

                "helicopter.status.update.error": "Helicopter status was not successfully updated.",
                "helicopter.visibility.update.error": "Helicopter visibility was not successfully updated.",

                "helicopter.delete.success": "Helicopter was successfully deleted.",
                "helicopter.delete.error": "Helicopter was not successfully deleted.",
            }
        },
        de: {
            translation: {
                //buttons and placeholders
                "navbar.map": 'Karte',
                "navbar.notifications": 'Benachrichtigung',
                "navbar.account": "Konto",
                "navbar.account.save": "Speichern",
                "navbar.account.placeholder.name": "Name",
                "navbar.account.placeholder.email": "Email",
                "navbar.account.placeholder.address": "Adresse",
                "navbar.account.placeholder.phonenumber": "Telefonnummer",
                "navbar.manageHeli": "Helikopterverwaltung",
                "navbar.support": "Support",
                "navbar.manageUsers": "Benutzerverwaltung",
                "navbar.logout": "Abmelden",

                "helicopter.ready": "Einsatzbereit in",
                "helicopter.operator": "Betreiber",
                "helicopter.number": "Nummer",
                "helicopter.watertank": "Ausrüstung",
                "helicopter.capacity": "Fassungsvermögen",
                "helicopter.liveURL": "Live-View",
                "helicopter.visibility": "Sichtweite", 
                "helicopter.type": "Type",
                "helicopter.addHelicopter": "Helikopter hinzufügen",
                "helicopter.addHelicopter.registration": "Anmeldung",
                "helicopter.addHelicopter.callsign": "Rufzeichen",
                "helicopter.addHelicopter.submit": "Hinzufügen",
                "helicopter.edit": "Bearbeiten",
                "helicopter.delete": "Löschen",
                "helicopter.delete.warning.title": "Helikopter löschen",
                "helicopter.delete.warning.text": "Dadurch wird ein Helikopter dauerhaft gelöscht. Bist du dir sicher, dass du fortfahren möchtest?",
                "helicopter.update": "Helikopter aktualisieren",
                "helicopter.update.success": "Erfolgreich aktualisiert",

                "support.title": "Support",
                "support.subject": "Betreff",
                "support.message": "Ihre Nachricht",
                "support.submit": "Übermitteln",

                "user.addUser": 'Benutzer erstellen',
                "user.updateUser": 'Benutzer aktualisieren',
                "user.edit": "Bearbeiten",
                "user.action": 'Aktion',

                "user.name": 'Name',
                "user.role": 'Rolle',
                "user.address": 'Adresse',
                "user.phoneNumber": 'Telefonnummer',
                "user.userName": 'Benutzername',
                "user.password": 'Passwort',
                "user.add": "Erstellen",
                "user.update": "Aktualisieren",
                "user.delete": "Löschen",
                "user.delete.warning.title": "Benutzer löschen",
                "user.delete.warning.text": "Dadurch wird dieser Benutzer dauerhaft gelöscht. Bist du dir sicher, dass du weitermachen willst?",
                "user.delete.warning.success": "Erfolgreich Gelöscht",

                //error messages
                "user.add.error.default": "Etwas ist schief gelaufen. Der Benutzer wurde nicht erfolgreich hinzugefügt.",
                "user.add.error.mobile": "Bitte geben Sie eine gültige Telefonnummer ein.",
                "user.add.error.email": "Bitte geben Sie eine gültige Email-Adresse ein.",
                "user.add.error.company": "Bitte geben Sie eine gültige Company-ID ein.",
                "user.add.error.company.exists": "Diese Company-ID ist bereits registriert.",
                "user.add.error.email.exists": "Diese E-Mail ist bereits registriert.",
                "user.add.success": "Benutzer erfolgreich hinzugefügt.",

                "user.update.error.invalidToken": "Ungültiges Token",
                "user.update.error.notFound": "Benutzer nicht gefunden",
                "user.update.success": "Benutzer erfolgreich aktualisiert.",

                "user.delete.error": "Der Benutzer wurde nicht erfolgreich gelöscht.",
                "user.delete.success": "Der Benutzer wurde erfolgreich gelöscht.",

                "helicopter.add.success": "Helikopter wurde erfolgreich hinzugefügt.",
                "helicopter.add.error": "Helikopter wurde nicht erfolgreich hinzugefügt.",

                "helicopter.update.success": "Der Helikopter wurde erfolgreich aktualisiert.",
                "helicopter.update.error": "Der Helikopter wurde nicht erfolgreich aktualisiert.",

                "helicopter.status.update.success": "Der Helikopter status wurde erfolgreich aktualisiert.",
                "helicopter.status.update.error": "Der Helikopter status wurde nicht erfolgreich aktualisiert.",

                "helicopter.visibility.update.success": "Die Sichtbarkeit des Helikopters wurde erfolgreich aktualisiert.",
                "helicopter.visibility.update.error": "Die Sichtbarkeit des Helikopters wurde nicht erfolgreich aktualisiert.",

                "helicopter.delete.success": "Helikopter wurde erfolgreich gelöscht.",
                "helicopter.delete.error": "Der Helikopter wurde nicht erfolgreich gelöscht.",
            }
        },
        fr: {
            translation: {
                //buttons and placeholders
                "navbar.map": 'Carte',
                "navbar.notifications": 'Notifications',
                "navbar.account": "Compte",
                "navbar.account.save": "Enregistrer",
                "navbar.account.placeholder.name": "Nom",
                "navbar.account.placeholder.email": "Email",
                "navbar.account.placeholder.address": "Adresse",
                "navbar.account.placeholder.phonenumber": "Numéro de téléphone",
                "navbar.manageHeli": "Gérer les Hélicoptères",
                "navbar.support": "Support",
                "navbar.manageUsers": "Gérer les Utilisateurs",
                "navbar.logout": "Déconnexion",

                "helicopter.ready": "Prêt dans",
                "helicopter.operator": "Opérateur",
                "helicopter.number": "Numéro",
                "helicopter.watertank": "Équipement",
                "helicopter.capacity": "Capacité",
                "helicopter.liveURL": "Live-View",
                "helicopter.visibility": "Visibilité", 

                "helicopter.type": "Type",
                "helicopter.addHelicopter": "Ajouter un Hélicoptère",
                "helicopter.addHelicopter.registration": "Immatriculation",
                "helicopter.addHelicopter.callsign": "Indicatif d'Appel",
                "helicopter.addHelicopter.submit": "Soumettre",
                "helicopter.edit": "Modifier",
                "helicopter.delete": "Supprimer",
                "helicopter.delete.warning.title": "Supprimer Hélicoptère",
                "helicopter.delete.warning.text": "Cela supprimera définitivement un hélicoptère. Êtes-vous sûr de vouloir continuer ?",
                "helicopter.update": "Mettre à jour Hélicoptère",
                "helicopter.update.success": "Mise à jour réussie",

                "support.title": "Soutien",
                "support.subject": "Sujet",
                "support.message": "Votre message",
                "support.submit": "Soumettre",

                "user.addUser": 'Créer Utilisateur',
                "user.updateUser": 'Mettre à jour Utilisateur',
                "user.edit": "Modifier",
                "user.action": 'Action',
                "user.name": 'Nom',
                "user.role": 'Rôle',
                "user.address": 'Adresse',
                "user.phoneNumber": 'Numéro de Téléphone',
                "user.userName": 'Nom d\'Utilisateur',
                "user.password": 'Mot de Passe',
                "user.add": "Créer",
                "user.update": "Mettre à jour",
                "user.delete": "Supprimer",
                "user.delete.warning.title": "Supprimer Utilisateur",
                "user.delete.warning.text": "Cela supprimera définitivement cet utilisateur. Êtes-vous sûr de vouloir continuer ?",
                "user.delete.warning.success": "Suppression réussie",

                //error messages
                "user.add.error.default": "Une erreur s'est produite. L'utilisateur n'a pas été ajouté avec succès.",
                "user.add.error.mobile": "Veuillez entrer un numéro de téléphone valide.",
                "user.add.error.email": "Veuillez entrer une adresse e-mail valide.",
                "user.add.error.company": "Veuillez entrer un identifiant d'entreprise valide.",
                "user.add.error.company.exists": "Cet identifiant d'entreprise est déjà enregistré.",
                "user.add.error.email.exists": "Cet e-mail est déjà enregistré.",
                "user.add.success": "Utilisateur ajouté avec succès.",

                "user.update.error.invalidToken": "Jeton non valide",
                "user.update.error.notFound": "Utilisateur introuvable",
                "user.update.success": "Utilisateur mis à jour avec succès.",

                "user.delete.error": "L'utilisateur n'a pas été supprimé avec succès.",
                "user.delete.success": "L'utilisateur a été supprimé avec succès.",

                "helicopter.add.success": "Hélicoptère ajouté avec succès.",
                "helicopter.add.error": "L'hélicoptère n'a pas été ajouté avec succès.",

                "helicopter.update.success": "Hélicoptère mis à jour avec succès.",
                "helicopter.update.error": "L'hélicoptère n'a pas été mis à jour avec succès.",

                "helicopter.status.update.success": "Statut de l'hélicoptère mis à jour avec succès.",
                "helicopter.status.update.error": "Le statut de l'hélicoptère n'a pas été mis à jour avec succès.",
                "helicopter.visibility.update.success": "La visibilité de l'hélicoptère a été mise à jour avec succès.",
                "helicopter.visibility.update.error": "La visibilité de l'hélicoptère n'a pas été mise à jour avec succès.",

                "helicopter.delete.success": "Hélicoptère supprimé avec succès.",
                "helicopter.delete.error": "L'hélicoptère n'a pas été supprimé avec succès.",
            }
        },
    }
});

export default I18n;