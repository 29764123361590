import React from 'react'

import PropTypes from 'prop-types'

import './helicopter-icon-stack.css'

const HelicopterIconStack = (props) => {
  return (
    <div className={`helicopter-icon-stack-container ${props.rootClassName} `}>
      <div className="helicopter-icon-stack-container1">
        <div className="helicopter-icon-stack-container2">
          <img
            alt="heli1"
            src="https://iili.io/HYla7Hv.png"
            className="helicopter-icon-stack-image"
          />
        </div>
        <div className="helicopter-icon-stack-container3">
          <img
            alt="heli1"
            src="https://iili.io/HYla7Hv.png"
            className="helicopter-icon-stack-image1"
          />
        </div>
        <div className="helicopter-icon-stack-container4">
          <img
            alt="heli1"
            src="https://iili.io/HYla7Hv.png"
            className="helicopter-icon-stack-image2"
          />
        </div>
        <div className="helicopter-icon-stack-container5">
          <img
            alt="heli1"
            src="https://iili.io/HYla7Hv.png"
            className="helicopter-icon-stack-image3"
          />
        </div>
        <svg
          viewBox="0 0 585.1428571428571 1024"
          className="helicopter-icon-stack-icon"
        >
          <path
            d="M585.143 402.286c0 9.714-4 18.857-10.857 25.714l-256 256c-6.857 6.857-16 10.857-25.714 10.857s-18.857-4-25.714-10.857l-256-256c-6.857-6.857-10.857-16-10.857-25.714 0-20 16.571-36.571 36.571-36.571h512c20 0 36.571 16.571 36.571 36.571z"
            className=""
          ></path>
        </svg>
      </div>
    </div>
  )
}

HelicopterIconStack.defaultProps = {
  rootClassName: '',
}

HelicopterIconStack.propTypes = {
  rootClassName: PropTypes.string,
}

export default HelicopterIconStack
