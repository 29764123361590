import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/navbar'
import DataTable from "react-data-table-component";
import BaseUrl from '../BaseUrl';
import auth from '../Auth';
import axios from 'axios';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Logo from '../assest/Element 3@4x.png'
import Heli from '../assest/Element 1@4x.png'

/**
* @author
* @function HelpCenter
**/

export const About = (props) => {
    const [data,setData] = useState('')
    const getAboutUs = async () => {
        const url = BaseUrl()+"get/about"
        try {
          const res = await axios.get(url, auth)
          console.log("res", res);
          setData(res.data.AboutUs);
        } catch (error) {
          console.log(error)
        }
      }
      useEffect(() => {
        getAboutUs();
      }, []);
    
  return(
    <>
    
    <div>
  <div className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar">
        <div className="navbar-navabar-icon-box">
         <Link to="/home" className="navbar-navlink">
            <img alt="" src={Logo} className="navbar-logo" />
          </Link>

          <img alt="" src={Heli} className="navbar-logo1" />
        </div>

        <div className="navbar-links-box">
          <div data-thq="thq-dropdown" className="navbar-map list-item">
            
          </div>

           <div data-thq="thq-dropdown" className="navbar-map list-item">
           
          </div>
        </div>
      </header>
      </div>
    <br/>
        <Container>
          <Col >
             <h1 className="mb-5" style={{color:"rgb(155,36,35)"}} align="center">About Us</h1>
             <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{color:"rgb(155,36,35)"}} >
            {data.about}
            <p><strong>Was ist FireTrack?</strong></p>
            <p>Firetrack.aero ist eine innovative Webapplikation, die sich auf das Echtzeit-Tracking von Status und Position von Feuerl&ouml;schflugzeugen und -hubschraubern spezialisiert hat. Unser engagiertes Team besteht aus Experten aus den Bereichen Luftfahrt, Feuerbek&auml;mpfung und Softwareentwicklung, die gemeinsam daran arbeiten, die Sicherheit und Effizienz der Feuerbek&auml;mpfung aus der Luft zu revolutionieren. Wir setzen modernste Technologien ein, um den Einsatz von Feuerl&ouml;schflugzeugen und -hubschraubern effizienter und sicherer zu gestalten.</p>
            <p>&nbsp;</p>
            <p><strong>Vision</strong></p>
            <p>Unsere Vision ist es, den Kampf gegen Wald- und Fl&auml;chenbr&auml;nde durch den Einsatz unserer Technologie zu unterst&uuml;tzen. Wir m&ouml;chten die Zusammenarbeit und Koordination zwischen Feuerwehren, Flugzeug- und Hubschrauberbetreibern sowie Beh&ouml;rden auf der ganzen Welt verbessern. Unser Ziel ist es, Leben und wertvolle Ressourcen zu sch&uuml;tzen und gleichzeitig den &ouml;kologischen Fu&szlig;abdruck der Branche zu reduzieren.</p>
            <p>&nbsp;</p>
            <p><strong>Waldbr&auml;nde in Europa</strong></p>
            <p>Waldbr&auml;nde sind eine immer gr&ouml;&szlig;er werdende Herausforderung in Europa. Durch den Klimawandel und die zunehmende Urbanisierung ist das Risiko von verheerenden Br&auml;nden in den letzten Jahren erheblich gestiegen. In Europa werden jedes Jahr durchschnittlich rund 50.000 Waldbr&auml;nde registriert, die mehr als 300.000 Hektar Land zerst&ouml;ren. Diese Br&auml;nde verursachen nicht nur enorme wirtschaftliche Sch&auml;den, sondern gef&auml;hrden auch das Leben von Menschen und Tieren sowie die Umwelt.</p>
            <p>&nbsp;</p>
            <p><strong>Effiziente Koordinierung von Feuerl&ouml;schflugzeugen und -hubschraubern</strong></p>
            <p>Wir bei Firetrack.aero sind davon &uuml;berzeugt, dass eine effiziente Koordinierung der L&ouml;schhelikopter und -flugzeuge entscheidend ist, um Waldbr&auml;nde erfolgreich zu bek&auml;mpfen. Unsere Webapplikation bietet Einsatzleitungen ein benutzerfreundliches Tool, um Feuerl&ouml;schflugzeuge und -hubschrauber in Echtzeit zu alarmieren, zu verfolgen und die Einsatzdauer zu &uuml;berwachen sowie den Einsatzverlauf zu analysieren. Dadurch k&ouml;nnen Ressourcen gezielt und effektiv eingesetzt werden, um Br&auml;nde schneller zu l&ouml;schen und Sch&auml;den zu minimieren.</p>
            <p>&nbsp;</p>
            <p><strong>Du m&ouml;chtest Zugriff auf die Plattform?</strong></p>
            <p>Sowohl Luftfahrzeugbetreiber als auch Einsatzleitstellen k&ouml;nnen von unserer Plattform profitieren. Wenn Sie sich f&uuml;r Firetrack.aero interessieren und Zugriff auf die Plattform erhalten m&ouml;chten, kontaktieren Sie uns bitte unter <a href="mailto:admin@firetrack.aero" target="_new">admin@firetrack.aero</a>. Unser Team steht Ihnen gerne zur Verf&uuml;gung, um Ihre Fragen zu beantworten und Ihnen bei der Implementierung unserer L&ouml;sung zu helfen.</p>
            <p>Wir freuen uns darauf, mit Ihnen zusammenzuarbeiten und gemeinsam die Feuerbek&auml;mpfung aus der Luft zu verbessern.</p>
            <p>Ihr Firetrack.aero Team</p>
       </div>
       </Col>
   </Container>
        </div>
    </>
   )

 }