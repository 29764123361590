import React, { useState, useEffect,useRef } from "react";
import "../../components/manage-helicopter-card.css";
import "../../views/manage-helicopter.css";
import BaseUrl from "../../BaseUrl";
import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { MultiSelect } from "react-multi-select-component";
import useOnClickOutside from "../use-onclick-outside";
import { useTranslation } from "react-i18next";

const HeliCardData = () => {
  const { i18n, t } = useTranslation();
  const [data, setdata] = useState([]);
  const [authRole, setAuthRole] = useState("");
  const [userId, setUserId] = useState("");
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef();
  useOnClickOutside(ref, () => setIsVisible(false));

  const token = localStorage.getItem("authorization");
  const auth = {
    headers: {
      authorization: `bearer ${token}`,
    },
  };

  const getAllHelicopter = async () => {
    const url = BaseUrl() + "get-helicopter";
    try {
      const res = await axios.get(url, auth);
      console.log("resssss",res)
      if (res.data.ResponseCode == 200) {
        setdata(res.data.getHelicopter);

      } else {
        // swal(res.data.ResponseMessage,{
        //   button:false
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const auth = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    getAllHelicopter();
    const role = localStorage.getItem("role");
    const id = localStorage.getItem("id");
    setUserId(base64_decode(id));
    setAuthRole(base64_decode(role));
    getAllUser();
  }, []);

  const updateHelicopterStatus = async (value, id) => {
    const url = BaseUrl() + "admin/update-status_helicopter"
    const data = {
      Einsatzbereit_in: value,
      id: id
    }
    try {
      const res = await axios.post(url, data, auth)
      // console.log("res>>>>", res)
      if (res.data.ResponseCode == 200) {
        getAllHelicopter()
        swal(t(`${res.data.ResponseMessage}`), {
          icon: "success",
        });
      }
    } catch (error) {
      // swal(error)
    }
  }

  const [id,setID] = useState("")
  const [heliId, setHeliId] = useState("");
  const [title, setTitle] = useState("");
  const [Einsatzbereit_in, setEinsatzbereit_in] = useState("");
  const [Betreiber, setBetreiber] = useState("");
  const [Wassertank, setWassertank] = useState("");
  const [Fassungsvermogen, setFassungsvermogen] = useState("");
  const [LiveFeedURL, setLiveFeedURL] = useState("");

  const [type, settype] = useState("");
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState("");
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [number,setNumber] = useState('')
  console.log("setSelected",selected)


  const handleClick = (row) => {
    console.log("row",row)
    setIsVisible(true)
    setHeliId(row.heliId);
    setTitle(row.title);
    setEinsatzbereit_in(row.Einsatzbereit_in );
    setBetreiber(row.Betreiber);
    setWassertank(row.Wassertank);
    setFassungsvermogen(row.Fassungsvermogen);
    setLiveFeedURL(row.LiveFeedURL);
    settype(row.type);
    setID(row.id);
    setIcon(row.icon)
    setImage(row.image)
    setNumber(row.number)
    // setSelected(row.userId)

     var opt1 = row.userId.map((item) => {
      console.log("item>>>",item)
          return { label: item.name, value: item._id }
          console.log("opt1>>",opt1)
        })
          setSelected(opt1)

          console.log("opt1>>",opt1)



      // for(var name of row.userId){
      //   console.log("name111",name)
      //   setSelected(row.userId)
      //   // setOptions(row.userId,{ label: row.name})
      // }
  };

  const deleteHelicopter = async (id) => {
    setID(id);
    swal({
      title: t("helicopter.delete.warning.title"),
      text: t("helicopter.delete.warning.text"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async(willDelete) => {
      
      if (willDelete) {
        const url = BaseUrl() + "admin/helicopter/delete"
        const body = {
          helicopter_Id: id
        };
        const res = await axios.post(url, body, auth);
        getAllHelicopter();
        swal(t("user.delete.warning.success"), {
          icon: "success",
          button:false
        });
      } else {
        // swal("Your imaginary file is safe!");
    }
     })
  }

  const updateHelicopter = async (event) => {
    event.preventDefault()
    const url = BaseUrl() + "update-helicopter";
    const fd = new FormData();
    fd.append("image", image);
    fd.append("heliId", heliId);
    fd.append("title", title);
    fd.append("Einsatzbereit_in", Einsatzbereit_in);
    fd.append("Betreiber", Betreiber);
    fd.append("Wassertank", Wassertank);
    fd.append("Fassungsvermogen", Fassungsvermogen);
    fd.append("LiveFeedURL", LiveFeedURL);
    fd.append("type", type);
    fd.append("icon", icon);
    fd.append('number',number)
    fd.append("id", id)

    selected.map((item) => {
      fd.append("user_id", item.value);
    })

    try {
      const res = await axios.post(url, fd, auth);
      console.log("res>>>>>>>..", res)
      if (res.data.ResponseCode == 200) {
        getAllHelicopter()
        swal(t(`${res.data.ResponseMessage}`), {
          icon: "success",
        });
        setIsVisible(false);
        setHeliId("");
        setTitle("");
        setEinsatzbereit_in("");
        setBetreiber("");
        setWassertank("");
        setFassungsvermogen("");
        setLiveFeedURL("")
        settype("");
        setID("");
        setSelected("")
        setNumber("")
      } else {
        swal(t(`${res.data.ResponseMessage}`))
      }
    } catch (err) {
      console.log("err", err);
      // swal(err.message);
    }
  };

  const getAllUser = async () => {
    const url = BaseUrl() + "get-all-user";
    try {
       const res = await axios.get(url, auth);
      if (res.data.ResponseCode == 200) {
        var user = res.data.getUser;
        var opt = user.map((item) => {
          return { label: item.name, value: item.id }
        })
        setOptions(opt)
       
      } else {
        // swal(res.data.ResponseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllUser();
  }, []);
   
  return (
    <>
      {isVisible &&
      <div className="popup-start ">
        <div ref={ref} >
          
            <form mathod="post" onSubmit={e => updateHelicopter(e)}>
              <div className="add-helicopter-container-container">
                <div className="add-helicopter-container-container1">
                  <div className="add-helicopter-container-comapany-details">
                    <span className="add-helicopter-container-text1">
                    {t("helicopter.update")}
                    </span>
                  </div>
                  <div className="add-helicopter-container-input-feilds">
                    <div className="add-helicopter-container-c1">
                      <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.addHelicopter.registration")}
                        className="add-helicopter-container-textinput input"
                        value={heliId}
                        onChange={(e) => {
                          setHeliId(e.target.value);
                        }}
                      />

                     {/* <input
                        type="text"
                        required
                        autoFocus
                        placeholder="Einsatzbereit in"
                        className="add-helicopter-container-textinput1 input"
                        value={Einsatzbereit_in}
                        onChange={(e) => {
                          setEinsatzbereit_in(e.target.value);
                        }}

                      />*/}
                      <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.watertank")}
                        className="add-helicopter-container-textinput2 input"
                        value={Wassertank}
                        onChange={(e) => {
                          setWassertank(e.target.value);
                        }}
                      />


                        <div className="add-helicopter-container-container41">
                          <MultiSelect
                            className="select1 rmsc1"
                            options={options}
                            value={selected}
                            onChange={setSelected}
                          />
                        </div>
                          <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.number")}
                        className="add-helicopter-container-textinput2 input"
                        value={number}
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                      />
                      <div className="add-helicopter-container-containernew">
                    <textarea
                      type="text"
                      required
                      autoFocus
                      placeholder={t("helicopter.operator")}
                      className="add-helicopter-container-textinput5 input  textarea"
                      value={Betreiber}
                      onChange={(e) => {
                        setBetreiber(e.target.value);
                      }}
                    />
                  </div>

                    </div>
                    <div className="add-helicopter-container-c2">
                      <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.addHelicopter.callsign")}
                        className="add-helicopter-container-textinput4 input"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />

                      <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.type")}
                        className="add-helicopter-container-textinput3 input"
                        value={type}
                        onChange={(e) => {
                          settype(e.target.value);
                        }}
                      />
                      <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.capacity")}
                        className="add-helicopter-container-textinput6 input"
                        value={Fassungsvermogen}
                        onChange={(e) => {
                          setFassungsvermogen(e.target.value);
                        }}
                      />
                      <div className="input-group custom-file-button">
                  <label className="input-group-text text" for="inputGroupFile1">Img</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="inputGroupFile1"
                      autoFocus
                      placeholder="Fassungsvermogen"
                      className="add-helicopter-container-fileinput6"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
              </div>
              <div className="input-group custom-file-button">
                  <label className="input-group-text text" for="inputGroupFile">Icon</label>
                      <input
                      type="file"
                      accept="image/png, image/jpeg"
                      autoFocus
                      placeholder="Fassungsvermogen"
                      className="add-helicopter-container-fileinput6 "
                      onChange={(e) => setIcon(e.target.files[0])}
                    />
              </div>
                    </div>
                  </div>

                  <div className="input-container">
                    <input
                      type="url"
                      autoFocus
                      placeholder={t("helicopter.liveURL")}
                      className="add-helicopter-container-textinput7 input"
                      value={LiveFeedURL}
                      onChange={(e) => {
                        setLiveFeedURL(e.target.value);
                      }}
                    />
                  </div>  
                  

                  <div className="add-helicopter-container-submit-button" >
                    <div className="add-helicopter-container-done-button">
                      <div className="add-helicopter-container-container5">
                        <button className="add-helicopter-container-text3">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => setIsVisible(false)}
                  className="add-helicopter-container-close-button"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    onClick={() => setIsVisible(false)}
                    className="add-helicopter-container-icon"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
      <div className="manage-helicopter-container3">
        {data.map((item) => {
          console.log("This is the item: ", item.userId)
          console.log("This is the id: ", base64_decode(localStorage.getItem("userId")))
          console.log("This is the id: ", base64_decode(localStorage.getItem("userId")))
          const hasAccess = item.userId?.some(userId => userId._id === base64_decode(localStorage.getItem("userId")))
          console.log("access: ", hasAccess)
          return (
            <div className={'manage-helicopter-card-container'}  key={item.id}>
              <img
                src={item.image}
                className="manage-helicopter-card-image"
              />
              <div className="manage-helicopter-card-content2">
                <div className="manage-helicopter-card-container01">
                  <div className="manage-helicopter-card-container02">
                    <div className="manage-helicopter-card-container03">
                      <span className="manage-helicopter-card-text">{item.heliId}</span>
                      <span className="manage-helicopter-card-text01">
                        {item.title}
                      </span>
                    </div>
                    <div className="manage-helicopter-card-einsatzbereit-in">
                      <span className="manage-helicopter-card-text02">
                        {t("helicopter.ready")}:
                      </span>
                      {(authRole === "admin" || (authRole === "user" && item.userId?.some(userId => userId._id === base64_decode(localStorage.getItem("userId"))))) ? (
                        <select 
                          className={`select-option-status status${item.Einsatzbereit_in}`} 
                          onFocus={e => e.target.size = 8} 
                          onBlur={e => e.target.size = 1}
                          onChange={(e) => {
                            e.target.size = 1;
                            updateHelicopterStatus(e.target.value, item.id);
                          }}
                        >
                          <option hidden value="">
                            {item.Einsatzbereit_in == "1" ? "15 Min" :
                             item.Einsatzbereit_in == "2" ? "30 Min" :
                             item.Einsatzbereit_in == "3" ? "60 Min" :
                             item.Einsatzbereit_in == "4" ? "2 Std" :
                             item.Einsatzbereit_in == "5" ? "3 Std" :
                             item.Einsatzbereit_in == "6" ? "Im Einsatz" :
                             item.Einsatzbereit_in == "7" ? "Abgemeldet N/A" :
                             item.Einsatzbereit_in == "8" ? "Im Betrieb" : ""}
                          </option>
                          <option className="select-option-status1" value="1">15 Min</option>
                          <option className="select-option-status2" value="2">30 Min</option>
                          <option className="select-option-status3" value="3">60 Min</option>
                          <option className="select-option-status4" value="4">2 Std</option>
                          <option className="select-option-status5" value="5">3 Std</option>
                          <option className="select-option-status6" value="6">Im Einsatz</option>
                          <option className="select-option-status7" value="7">Abgemeldet N/A</option>
                          <option className="select-option-status8" value="8">Im Betrieb</option>
                        </select>
                      ) : (
                        <span className={`text-center select-option-status status${item.Einsatzbereit_in}`}>
                          {item.Einsatzbereit_in == "1" ? "15 Min" :
                           item.Einsatzbereit_in == "2" ? "30 Min" :
                           item.Einsatzbereit_in == "3" ? "60 Min" :
                           item.Einsatzbereit_in == "4" ? "2 Std" :
                           item.Einsatzbereit_in == "5" ? "3 Std" :
                           item.Einsatzbereit_in == "6" ? "Im Einsatz" :
                           item.Einsatzbereit_in == "7" ? "Abgemeldet N/A" :
                           item.Einsatzbereit_in == "8" ? "Im Betrieb" : ""}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="manage-helicopter-card-container04">
                  <div className="manage-helicopter-card-container05">
                    <div className="manage-helicopter-card-container06">
                      <span className="manage-helicopter-card-text03">{t("helicopter.operator")}:</span>
                      <span className="manage-helicopter-card-text04">
                        {item.Betreiber}
                        <br/>
                        {item.number}
                      </span>
                     
                    </div>
                    <div className="manage-helicopter-card-container07">
                      <span className="manage-helicopter-card-text05">{t("helicopter.watertank")}:</span>
                      <span className="manage-helicopter-card-text06">
                        {item.Wassertank}
                      </span>
                    </div>
                  </div>
                  <div className="manage-helicopter-card-container08">
                    <div className="manage-helicopter-card-container09">
                      <span className="manage-helicopter-card-text07">
                        <span className="">{t("helicopter.capacity")}:</span>
                        <span className="manage-helicopter-card-text09"></span>
                      </span>
                      <span className="manage-helicopter-card-text10">{item.Fassungsvermogen}</span>
                    </div>
                    <div className="manage-helicopter-card-container10">
                      <span className="manage-helicopter-card-text11">{t("helicopter.type")}:</span>
                      <span className="manage-helicopter-card-text12">
                        {item.type}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="manage-helicopter-card-buttons">
                  {authRole == "admin" &&
                    <button className="manage-helicopter-card-container11" onClick={e=>handleClick(item)}>
                      <div className="manage-helicopter-card-container12">
                        <span className="manage-helicopter-card-text13">
                        {t("helicopter.edit")}</span>
                      </div>
                    </button>


                  }
                  {authRole == "admin" &&
                    <button className="manage-helicopter-card-container11" onClick={e=>deleteHelicopter(item.id)}>
                      <div className="manage-helicopter-card-container12">
                        <span className="manage-helicopter-card-text13">
                        {t("helicopter.delete")}</span>
                      </div>
                    </button>
                    

                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default HeliCardData;
