import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import HelpCenterPopUp from "../components/help-center-pop-up";
import BaseUrl from '../BaseUrl';
// import auth from '../Auth';
import axios from 'axios';
import swal from 'sweetalert';
import useOnClickOutside from "./use-onclick-outside";
import Heli from '../assest/Element 1@4x.png'
import Logo from '../assest/Element 3@4x.png'
import Avatar from '../assest/HYag1af.png'
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  const [show, setShow] = useState(false);
  const [ShowProfile, setShowProfile] = useState(false)
  const [ShowNotification, setShowNotification] = useState(false)
  const [authRole, setAuthRole] = useState("");
  const [data, setData] = useState("")
  const [notificationData, setNotifictionData] = useState([])
  const [name, setName] = useState("");
  const [language, setLanguage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [company_Id, setComponyId] = useState("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState();
  const [id, setId] = useState();
  const { i18n, t } = useTranslation();

  const ref = useRef()

  useOnClickOutside(ref, () => {
    setShowProfile(false)
    setShowNotification(false)
  });

  const token = localStorage.getItem("authorization")
  var auth

  const LANGUAGES = [
    { label: "EN", code: "en" },
    { label: "DE", code: "de" },
    { label: "FR", code: "fr" },
  ];

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    console.log("Language has been changed")
    localStorage.setItem("language", lang_code)
    UpdateUserLanguage(lang_code)
    i18n.changeLanguage(lang_code);
  };

  const handleClick = (item) => {
    setName(item.name);
    setEmail(item.email);
    setMobileNumber(item.mobile_number);
    setComponyId(item.company_Id);
    setAddress(item.address);
    setRole(item.role);
    setId(item.id);
  };
  
  const UpdateUserLanguage = async (lang_code) => {
    var auth = {
        headers: {
          authorization: `bearer ${token}`,
        },
    };
    const url = BaseUrl() + "user/update";
    const data = {
      language: lang_code,
    }
    console.log("Setting up language preferences ", lang_code)
    try {
      const res = await axios.put(url, data, auth)
      if (res.data.ResponseCode == 200) {
        console.log("updated language preferences", res)
      } else {
        console.log("Something went wrong with saving the language preferences")

      }

    } catch (error) {
      console.log(error)
      swal(error)
    }
  }

  const UpdateUser = async (e) => {
    e.preventDefault();
    console.log("click")
    var auth = {
        headers: {
          authorization: `bearer ${token}`,
        },
    };
    const url = BaseUrl() + "user/update";
    const data = {
      name: name,
      email: email,
      password: password,
      mobile_number: mobile_number,
      company_Id: company_Id,
      address: address,
    }
    try {
      const res = await axios.put(url, data, auth)
      if (res.data.ResponseCode == 200) {
        setShowProfile(false)
        console.log("res", res)
        swal(t(`${res.data.ResponseMessage}`), {
          icon: "success",
          button:false
        });
      } else {
        swal(t(`${res.data.ResponseMessage}`),{
          button:false
        })
      }

    } catch (error) {
      console.log(error)
      swal(error)
    }
  }

  const history = useHistory()


  const getNotifaction = async () => {
      const token = await localStorage.getItem("authorization")
      console.log("token",token)
      var auth = {
  headers: {
    authorization: `bearer ${token}`,
  },
};

    const url = BaseUrl() + "get-notifaction"
    try {
      const res = await axios.get(url, auth)
      console.log("res", res)
      if (res.data.ResponseCode == 200) {
        setNotifictionData(res.data.Notification)
        setShowNotification(true)
        setShowProfile(false)
      } else {
        swal(t(`${res.data.ResponseMessage}`),{
          button:false
        });
      }
    } catch (error) {
      swal(error)
      console.log(error)
    }
  }

  const getUserProfile = async () => {
    const token = await localStorage.getItem("authorization")
      var auth = {
      headers: {
       authorization: `bearer ${token}`,
      },
    };

  const url = BaseUrl() + "user/alldata";
    try {
      const res = await axios.get(url, auth);
      if (res.data.ResponseCode == 200) {
        setData(res.data.userFind);
        handleClick(res.data.userFind)
      } else {
        swal(t(`${res.data.ResponseMessage}`),{
            buttons: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logOut = () => {
    localStorage.clear();
    history.push("/");
  };


  useEffect(() => {
    const role = localStorage.getItem("role");
    setAuthRole(base64_decode(role));
 var auth = localStorage.getItem("authorization")  
  }, []);
  return (
    <div className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar">
        <div className="navbar-navabar-icon-box">
          <Link to="/home" className="navbar-navlink">
            <img alt={props.Logo_alt} src={props.Logo_src} className="navbar-logo" />
          </Link>
          <img alt={props.Logo_alt1} src={props.Logo_src1} className="navbar-logo1" />
        </div>

        <div className="navbar-links-box">
          {/*<div data-thq="thq-dropdown" className="navbar-map list-item">
            <span onClick={() => { history.push("/about-us") }} className="text-link">
              <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle">
                <span className="navbar-text01">{props.about}</span>
              </div>
            </span>
          </div>*/}
          {/* add language here */}
        

           <div data-thq="thq-dropdown" className="navbar-map list-item">
            <span onClick={() => { history.push("/home") }} className="text-link">
              <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle">
                <span className="navbar-text01">{t("navbar.map")}</span>
              </div>
            </span>
          </div>

          <div data-thq="thq-dropdown"  className="navbar-notifications list-item" onClick={() => {
              getNotifaction()
            }}
          >
            <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle01">
              <span className="navbar-text01">{t("navbar.notifications")}</span>
            </div>
          </div>

          {/* { authRole == "user" &&
            <>
              <div data-thq="thq-dropdown" className="navbar-help-center list-item">
                <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle02" >
                  <span className="navbar-text03"
                    onClick={() => {
                      setShowProfile(false);
                      setShow(true);
                    }}
                  >{props.text2}
                  </span>
                </div>
              </div>
            </>
          } */}

          <div data-thq="thq-dropdown" className="navbar-map list-item">
              <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle">
                  <select  className="navbar-language" defaultValue={ localStorage.getItem("language") ? localStorage.getItem("language") : i18n.language} onChange={onChangeLang}>
                    {LANGUAGES.map(({ code, label }) => (
                      <option key={code} value={code}>
                        {label}
                      </option>
                    ))}
                  </select>
              </div>
          </div>

          {/* MOBILE VIEW menu start */}
          <div data-thq="thq-dropdown" className="navbar-mobile-menu list-item">
            <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle03">
              <svg viewBox="0 0 1024 1024" className="navbar-icon">
                <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z" className=""></path>
              </svg>
            </div>
            <ul data-thq="thq-dropdown-list" className="navbar-profile-icon-dropdown">
              <li data-thq="thq-dropdown" className="navbar-map1 list-item">
                <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle04" >
                  <span onClick={() => { history.push("/home") }} className="navbar-navlink2">
                    <span className="navbar-text04">{t("navbar.map")}</span>
                    <br className=""></br>
                  </span>
                </div>
              </li>
              <li data-thq="thq-dropdown" className="navbar-notifications1 list-item">
                <div data-thq="thq-dropdown-toggle"onClick={() => {
                    setShowNotification(true)
                    setShowProfile(false)
                    getNotifaction()

                  }}
                  onMouseLeave={() => setShowNotification(false)} className="navbar-dropdown-toggle05" >
                  <span className="navbar-text06">{t("navbar.notifications")}</span>
                  {/* <div className="navbar-container01">
                    <span className="navbar-text07">12</span>
                  </div> */}
                </div>
              </li>

              <li data-thq="thq-dropdown" className="navbar-notifications1 list-item">
                <div data-thq="thq-dropdown-toggle" className="navbar-dropdown-toggle">
                    <select  className="navbar-language" defaultValue={i18n.language} onChange={onChangeLang}>
                      {LANGUAGES.map(({ code, label }) => (
                        <option key={code} value={code}>
                          {label}
                        </option>
                      ))}
                    </select>
                </div>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-help-center list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle06"
                >
                  {/* <span className="navbar-text08">{props.text4}</span> */}
                </div>
              </li>
            </ul>
          </div>
          {/* MOBILE VIEW menu end */}
          
          {authRole == "admin" ? (
            <div data-thq="thq-dropdown"
              className="navbar-profile-icon list-item">
              <div
                data-thq="thq-dropdown-toggle"
                className="navbar-dropdown-toggle07"
              >
                <img
                  alt={props.image_alt}
                  src={props.image_src}
                  className="navbar-image"
                />
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="navbar-profile-icon-dropdown1"
              >
                <li data-thq="thq-dropdown" className="navbar-profile list-item">
                  <div
                    data-thq="thq-dropdown-toggle"
                    onClick={() => {
                      setShowProfile(true)
                      setShowNotification(false)
                    }}
                    className="navbar-dropdown-toggle08"
                  >
                    <span className="navbar-text09"
                      onClick={() => {
                        getUserProfile(data)
                      }}
                    >{t("navbar.account")}</span>
                  </div>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="navbar-manage-helicopter list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle09"
                  >
                    <span onClick={() => { history.push("/manage-helicopter") }} className="navbar-navlink3">
                    {t("navbar.manageHeli")}
                    </span>
                  </div>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="navbar-user-management list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle10"
                  >
                    <span onClick={() => { history.push("/user-management") }} className="navbar-navlink4">
                    {t("navbar.manageUsers")}
                    </span>
                  </div>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="navbar-help-center-manage list-item"
                >
                </li>

                  {/* <li
                  data-thq="thq-dropdown"
                  className="navbar-help-center-manage list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle11"
                  >
                    <span onClick={() => { history.push("/add-about")}} className="navbar-text10">{props.addAbout}</span>
                  </div>
                </li>

                  <li
                  data-thq="thq-dropdown"
                  className="navbar-help-center-manage list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle11"
                  >
                    <span onClick={() => { history.push("/add-landing")}} className="navbar-text10">{props.landing}</span>
                  </div>
                </li> */}

                <li data-thq="thq-dropdown" className="navbar-logout list-item">
                  <span onClick={logOut}>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle12"
                    >
                      <span className="navbar-text11">{t("navbar.logout")}</span>
                    </div>
                  </span>
                </li>
              </ul>
            </div>
          ) : (
            <div
              data-thq="thq-dropdown"
              className="navbar-profile-icon2 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="navbar-dropdown-toggle13"
              >
                <img
                  alt={props.image_alt1}
                  src={props.image_src1}
                  className="navbar-image1"
                />
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="navbar-profile-icon-dropdown2"
              >
                <li data-thq="thq-dropdown" className="navbar-profile1 list-item">
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle14"
                    onClick={() => {
                      setShowProfile(true)
                      setShow(false)
                    }}
                  >
                    <span className="navbar-text12"
                      onClick={() => {
                        handleClick(data)
                        getUserProfile(data)
                      }}
                    >{t("navbar.account")}</span>
                  </div>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="navbar-manage-helicopter1 list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle15"
                  >
                    <span onClick={() => { history.push("/manage-helicopter") }} className="navbar-navlink6">
                      Helikopter
                    </span>
                  </div>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="navbar-user-management1 list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle16"
                  >
                    <Link to="/user-management" className="navbar-navlink7">
                    {t("navbar.manageUsers")}
                    </Link>
                  </div>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="navbar-help-center-manage1 list-item"
                >
                  <div
                    data-thq="thq-dropdown-toggle"
                    className="navbar-dropdown-toggle17"
                  >
                    <span className="navbar-text13">{t("navbar.support")}</span>
                  </div>
                </li>
                <li data-thq="thq-dropdown" className="navbar-logout1 list-item">
                  <span onClick={logOut} className="">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle18"
                    >
                      <span className="navbar-text14">{t("navbar.logout")}</span>
                    </div>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </header>
      {ShowProfile  &&
        <div className='popup-start'>
        <div ref={ref} >
          <div className="navbar-container02">
            <div className="navbar-container03">
              <div onClick={() => setShowProfile(false)} className="navbar-close-button" >
                <svg viewBox="0 0 1024 1024" onClick={() => setShowProfile(false)} className="navbar-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z" className="" ></path>
                </svg>
              </div>
              <div className="navbar-container04">
                <div className="navbar-comapany-details">
                  <span className="navbar-text15">{t("navbar.account")}</span>
                </div>
                <div className="navbar-input-feilds">
                  <input
                    type="text"
                    required
                    autoFocus
                    placeholder={t("navbar.account.placeholder.name")}
                    className="input"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    required
                    autoFocus
                    placeholder={t("navbar.account.placeholder.email")}
                    className="navbar-textinput1 input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    required
                    autoFocus
                    placeholder={t("navbar.account.placeholder.address")}
                    className="navbar-textinput2 input"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    required
                    autoFocus
                    placeholder={t("navbar.account.placeholder.phonenumber")}
                    className="navbar-textinput3 input"
                    value={mobile_number}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                  />
                </div>
                <div className="navbar-done-button" onClick={UpdateUser}>
                  <div className="navbar-container05">
                    <span className="navbar-text16" onClick={UpdateUser}>{t("navbar.account.save")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      }
      { ShowNotification && 
        <div ref={ref} onMouseLeave={() => setShowNotification(false)} className="navbar-container06 hide-scroll">
        {notificationData.map((item) => {
            return (
              <div className="navbar-n-container">
                <div className="navbar-container07">
                  <span className="navbar-text17">{item.heliCopterTitle}</span>
                  <span className="navbar-text18">
                    <span className="">
                      {item.message}
                    </span>
                    {/*  <span className="">velit valutpat tempus at nequ dolor.</span>*/}
                    <br className=""></br>
                  </span>
                </div>
                <div className="navbar-container08">
                  <span className="navbar-text22">{item.time}</span>
                  <div className="navbar-container09">
                    <div className="navbar-container10">
                      <img className=" navbar-container10 " src={item.helicopterImage} height='28px' />
                    </div>
                  </div>
                </div>
              </div>
            )
        })}
        </div>
      }
      <HelpCenterPopUp
        rootClassName=""
        show={show}
        onHide={() => setShow(false)}
      />
    </div>
  )
}

Navbar.defaultProps = {
  text10: 'Karte',
  about: 'About Us',
  addAbout:'About Us',
  addAbout:'About Us',
  landing:"Impressum",
  rootClassName2: '',
  text71: 'User Management',
  text12: 'Text',
  image_src: Avatar,
  text81: 'Help Center Manage',
  image_src1: Avatar,
  text91: 'Abmelden',
  text6: 'Helikopter verwalten',
  rootClassName: '',
  text4: 'Help Center',
  Logo_alt1: 'logo',
  text3: 'Benachrichtigung',
  text5: 'Konto',
  text: 'Karte',
  Logo_src: Logo,
  text51: 'Konto',
  rootClassName1: '',
  text9: 'Abmelden',
  image_alt1: 'image',
  text61: 'Helikopter verwalten',
  text8: 'Support',
  text2: 'Help Center',
  text11: 'Text',
  Logo_src1: Heli,
  text1: 'Benachrichtigung',
  text7: 'Benutzerverwaltung',
  Logo_alt: 'logo',
  image_alt: 'image',
}

Navbar.propTypes = {
  text10: PropTypes.string,
  about: PropTypes.string,
  landing: PropTypes.string,
  addAbout: PropTypes.string,
  rootClassName2: PropTypes.string,
  text71: PropTypes.string,
  text12: PropTypes.string,
  image_src: PropTypes.string,
  text81: PropTypes.string,
  image_src1: PropTypes.string,
  text91: PropTypes.string,
  text6: PropTypes.string,
  rootClassName: PropTypes.string,
  text4: PropTypes.string,
  Logo_alt1: PropTypes.string,
  text3: PropTypes.string,
  text5: PropTypes.string,
  text: PropTypes.string,
  Logo_src: PropTypes.string,
  text51: PropTypes.string,
  rootClassName1: PropTypes.string,
  text9: PropTypes.string,
  image_alt1: PropTypes.string,
  text61: PropTypes.string,
  text8: PropTypes.string,
  text2: PropTypes.string,
  text11: PropTypes.string,
  Logo_src1: PropTypes.string,
  text1: PropTypes.string,
  text7: PropTypes.string,
  Logo_alt: PropTypes.string,
  image_alt: PropTypes.string,
}

export default Navbar
