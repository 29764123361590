import React, { useState ,useEffect,useRef} from 'react'
import { decode as base64_decode, encode as base64_encode } from "base-64";
import swal from 'sweetalert';
import PropTypes from 'prop-types'
import BaseUrl from "../BaseUrl";
import auth from "../Auth";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import useOnClickOutside from "./use-onclick-outside";
import { useTranslation } from "react-i18next";
import './add-helicopter-container.css'

  

const AddHelicopterContainer = (props) => {
  const { i18n, t } = useTranslation();
  const [data, setdata] = useState([]);
  const [user, setUser] = useState([]);
  const [heliId, setHeliId] = useState("");
  const [title, setTitle] = useState("");
  // const [Einsatzbereit_in, setEinsatzbereit_in] = useState("");
  const [Betreiber, setBetreiber] = useState("");
  const [Wassertank, setWassertank] = useState("");
  const [Fassungsvermogen, setFassungsvermogen] = useState("");
  const [LiveFeedURL, setLiveFeedURL] = useState("");
  const [type, settype] = useState("");
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState("");
  const [user_id, setUser_id] = useState(0);
  const [id,setID] =useState('')
  const [authRole, setAuthRole] = useState("");
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [isVisible,setIsVisible] =useState(false)
  const [number,setNumber] = useState('')
  const value = []
  const ref = useRef();
   useOnClickOutside(ref, () => setIsVisible(false));

  const opti = selected.map((item) => {
          return value.push(item.value)
        })

  const getAllUser = async () => {
    const url = BaseUrl() + "get-all-user";
    try {
      const res = await axios.get(url, auth);
      if (res.data.ResponseCode == 200) {
        var user = res.data.getUser;
        var opt = user.map((item) => {
          return { label: item.name, value: item.id }
        })
       
        setOptions(opt)

      } else {
        // swal(res.data.ResponseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };



    const addHelicopter = async (e) => {
    e.preventDefault();
    
    const url = BaseUrl() + "user/add-helicopter";
    const fd = new FormData();
    fd.append("image", image);
    fd.append("heliId", heliId);
    fd.append("title", title);
    // fd.append("Einsatzbereit_in", Einsatzbereit_in);
    fd.append("Betreiber", Betreiber);
    fd.append("Wassertank", Wassertank);
    fd.append("Fassungsvermogen", Fassungsvermogen);
    fd.append("LiveFeedURL", LiveFeedURL);
    fd.append("type", type);
    fd.append("icon",icon);
    fd.append("number",number);

    // fd.append("user_id[]",[]value);

        for (let usr of value) {
      fd.append("user_id", usr);
    }

    console.log("value.value",value)
    try {
      const res = await axios.post(url, fd, auth);
      console.log("res",res)
      if (res.data.ResponseCode == 200) {
        swal(t(`${res.data.ResponseMessage}`), {
          icon: "success",
        }).then(function() {
          setIsVisible(false);
          setHeliId("");
          setTitle("");
          // setEinsatzbereit_in("");
          setBetreiber("");
          setWassertank("");
          setFassungsvermogen("");
          setLiveFeedURL("")
          setImage("");
          settype("");
          setUser_id("");
          setNumber("")
          window.location.reload(false);
          });
      } else {
        swal(t(`${res.data.ResponseMessage}`));
        console.log(res.data)
      }
    } catch (err) {
      console.log("err", err);
      swal(err.message);
    }
  };


    useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role");
    setAuthRole(base64_decode(role));
  },[options]);


  return (
    <>
    <div className="add-helicopter-container-add-helicopter-container">
     {authRole == "admin" ? (
      <div

        onClick={() => setIsVisible(true)}
        className="add-helicopter-container-add-helicopter-button"
      >
        <span className="add-helicopter-container-text">{t("helicopter.addHelicopter")}</span>
      </div>
         ):(
          ""
          )}
  
    </div>
  

     {isVisible  ? (
        <div className='popup-start'>
      
         <div ref={ref} >
       <form mathod="post" onSubmit={e => addHelicopter(e)}>
        <div className="add-helicopter-container-container">
          <div className="add-helicopter-container-container1">
            <div className="add-helicopter-container-comapany-details">
              <span className="add-helicopter-container-text1">
                {t("helicopter.addHelicopter")}
              </span>
            </div>
            <div className="add-helicopter-container-input-feilds">
              <div className="add-helicopter-container-c1">
                <input
                  type="text"
                  required
                  autoFocus
                  placeholder={t("helicopter.addHelicopter.registration")}
                  className="add-helicopter-container-textinput input"
                  value={heliId}
                   onChange={(e) => {
                      setHeliId(e.target.value);
                   }}
                />
               
                <input
                  type="text"
                  required
                  autoFocus
                  placeholder={t("helicopter.watertank")}
                  className="add-helicopter-container-textinput2 input"
                  value={Wassertank}
                  onChange={(e) => {
                  setWassertank(e.target.value);
                  }}
                />

              

                {/* <input
                  type="text"
                  required
                  autoFocus
                  placeholder="Wassertank"
                  className="add-helicopter-container-textinput2 input"
                  value={Wassertank}
                  onChange={(e) => {
                  setWassertank(e.target.value);
                  }}
                />
                */}
              
            <div className="add-helicopter-container-container41">
              <MultiSelect
              className="select1 rmsc1 hide-scro"
                options={options}
                value={selected}
                onChange={setSelected}
              />
            </div>
              <input
                        type="text"
                        required
                        autoFocus
                        placeholder={t("helicopter.number")}
                        className="add-helicopter-container-textinput2 input"
                        value={number}
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                      />
            
                  <textarea 
                  type="text"
                  required
                  autoFocus
                  placeholder={t("helicopter.operator")}
                  className="add-helicopter-container-textinput5 input  textarea"
                  value={Betreiber}
                  onChange={(e) => {
                  setBetreiber(e.target.value);
                  }}
                />
                
              </div>
              <div className="add-helicopter-container-c2">
                <input
                  type="text"
                  required
                  autoFocus
                  placeholder={t("helicopter.addHelicopter.callsign")}
                  className="add-helicopter-container-textinput4 input"
                  value={title}
                    onChange={(e) => {
                    setTitle(e.target.value);
                    }}
                />

                   <input
                  type="text"
                  required
                  autoFocus
                  placeholder={t("helicopter.type")}
                  className="add-helicopter-container-textinput3 input"
                  value={type}
                  onChange={(e) => {
                  settype(e.target.value);
                  }}
                />

                <input
                  type="text"
                  required
                  autoFocus
                  placeholder={t("helicopter.capacity")}
                  className="add-helicopter-container-textinput6 input"
                  value={Fassungsvermogen}
                  onChange={(e) => {
                  setFassungsvermogen(e.target.value);
                    }}
                />
              <div className="input-group custom-file-button">
                  <label className="input-group-text text" for="inputGroupFile1">Img</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      id="inputGroupFile1"
                      required
                      autoFocus
                      placeholder="Fassungsvermogen"
                      className="add-helicopter-container-fileinput6"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
              </div>
              <div className="input-group custom-file-button">
                  <label className="input-group-text text" for="inputGroupFile">Icon</label>
                      <input
                      type="file"
                      accept="image/png, image/jpeg"
                      required
                      autoFocus
                      placeholder="Fassungsvermogen"
                      className="add-helicopter-container-fileinput6 "
                      onChange={(e) => setIcon(e.target.files[0])}
                    />
              </div>



              </div>

            </div>
                    
            
            
            <div className="add-helicopter-container-containernew">
            
                </div>

            <div className="input-container">
              <input
                type="url"
                autoFocus
                placeholder={t("helicopter.liveURL")}
                className="add-helicopter-container-textinput7 input"
                value={LiveFeedURL}
                onChange={(e) => {
                  setLiveFeedURL(e.target.value);
                }}
              />
            </div>
            
            <div className="add-helicopter-container-submit-button" >
              <div className="add-helicopter-container-done-button">
                <div className="add-helicopter-container-container5">
                  <button className="add-helicopter-container-text3">{t("helicopter.addHelicopter.submit")}</button>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setIsVisible(false)}
            className="add-helicopter-container-close-button"
          >
            <svg
              viewBox="0 0 1024 1024"
              onClick={() => setIsVisible(false)}
              className="add-helicopter-container-icon"
            >
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
        </div>
        </form>
        </div>
        </div>
 ) : (
 ""
      
      )}
    </>
  )
}

AddHelicopterContainer.defaultProps = {
  rootClassName: '',
  text: 'Helikopter Hinzufügen',
}

AddHelicopterContainer.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default AddHelicopterContainer

