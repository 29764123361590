const token = localStorage.getItem("authorization");
const auth = {
  headers: {
    authorization: `bearer ${token}`,
  },
};


export default auth;


