import React, { useState,useEffect } from 'react'
import Navbar from '../components/navbar'
import { Link } from 'react-router-dom'
import DataTable from "react-data-table-component";
import BaseUrl from '../BaseUrl';
import auth from '../Auth';
import axios from 'axios';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Logo from '../assest/Element 3@4x.png'
import Heli from '../assest/Element 1@4x.png'

/**
* @author
* @function Impressum
**/

export const Impressum = (props) => {
    const [data,setData] = useState('')
    const getImpressum = async () => {
        const url = BaseUrl()+"content"
        try {
          const res = await axios.get(url, auth)
          console.log("res", res);
          setData(res.data.content.content);
        } catch (error) {
          console.log(error)
        }
      }
      useEffect(() => {
        getImpressum();
      }, []);
    
  return(
    <>
    
    <div>
  <div className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar">
        <div className="navbar-navabar-icon-box">
        <Link to="/home" className="navbar-navlink">
            <img alt="" src={Logo} className="navbar-logo" />
             </Link>
          <img alt="" src={Heli} className="navbar-logo1" />
        </div>
        <div className="navbar-links-box">
          <div data-thq="thq-dropdown" className="navbar-map list-item">
          </div>
           <div data-thq="thq-dropdown" className="navbar-map list-item">
          </div>
        </div>
      </header>
      </div>
    <br/>
        <Container>
          <Col >
             <h1 className="mb-5" style={{color:"rgb(155,36,35)"}} align="center">Impressum</h1>
             <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{color:"rgb(155,36,35)"}} >
          {data}
          <p><strong>Kontaktadresse</strong><br />FHS International Managment AG<br />Neuhofstrasse 1, CH-6340 Baar<br />admin@firetrack.aero</p>
          <p><strong>Copyright</strong><br />Das Copyright des Konzepts, der Bilder/Fotos und der textlichen Inhalte liegen bei FHS International Management AG.</p>
          <p><strong>Haftungsbeschr&auml;nkung</strong><br />Mit der Nutzung dieser Website akzeptieren Sie die nachfolgenden Bedingungen: Die Website wurde mit gr&ouml;sstm&ouml;glicher Sorgfalt erstellt. Die FHS International Management AG &uuml;bernimmt jedoch keine Gew&auml;hr f&uuml;r der Richtigkeit, Vollst&auml;ndigkeit, Exaktheit und Aktualit&auml;t der auf dieser Webseite bereitgestellten Inhalte. Ebenso wird keine Gew&auml;hrleistung hinsichtlich der Qualit&auml;t der Inhalte &uuml;bernommen, die &uuml;ber diese Webseite weitergegeben oder heruntergeladen werden.&nbsp;FHS International Management AG beh&auml;lt sich vor, Teile der Website oder das gesamte Angebot ohne spezielle Ank&uuml;ndigung zu ver&auml;ndern, erg&auml;nzen oder l&ouml;schen.</p>
          <p><strong>Urheber- und Leistungsschutzrechte</strong><br />Alle auf dieser Website ver&ouml;ffentlichten Inhalte unterliegen dem Urheber- und Leistungsschutzrecht. Inhalte dieser Website d&uuml;rfen nur mit vorg&auml;ngiger, schriftlicher Zustimmung und unter Angabe des Copyrights verwendet werden. Dies gilt insbesondere f&uuml;r Vervielf&auml;ltigung, Bearbeitung, &Uuml;bersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet.</p>
          <p><strong>Externe Links</strong><br />Diese Website enth&auml;lt Verkn&uuml;pfungen zu &laquo;externen Links&raquo; (Websites Dritter). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Wir haben keinen Einfluss auf die aktuelle und zuk&uuml;nftige Gestaltung und auf die Inhalte der verkn&uuml;pften Seiten. Bei Kenntnis von Rechtsverst&ouml;ssen werden die entsprechenden externen Links unverz&uuml;glich gel&ouml;scht. Dem Benutzer von externen Links muss bewusst sein, dass die Betreiber von verlinkten Websites pers&ouml;nliche Daten erfassen und sammeln k&ouml;nnen (inklusive Informationen, die durch die Benutzung von Cookies generiert wurden), sobald deren Websites besucht werden. Wir &uuml;bernehmen keine Verantwortung daf&uuml;r, wie derartige Betreiber pers&ouml;nlichen Daten sammeln, verwenden oder offenlegen.</p>
       </div>
       </Col>
     </Container>
        </div>
    </>
   )
 }