import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Table from 'react-bootstrap/Table';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import AddUserManagement from "../components/add-user-management";
import "./user-management.css";
import BaseUrl from "../BaseUrl";
import auth from "../Auth";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner'

import AddButtonAndSearch from "../components/add-button-and-search";
import useOnClickOutside from "../components/use-onclick-outside";
import {decode as base64_decode, encode as base64_encode} from 'base-64'

const UserManagement = (props) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [search, setSerach] = useState("");
  const [ShowUpdateUser, setShowUpdateUser] = useState(false);
  const { i18n, t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [company_Id, setComponyId] = useState("");
  const [address, setAddress] = useState("");
  const [role, setRole] = useState();
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);
  var a = 0
  a++

  const ref = useRef();

    useOnClickOutside(ref, () => setShowUpdateUser(false));


  const token = localStorage.getItem("authorization");
  const auth = {
    headers: {
      authorization: `bearer ${token}`,
    },
  };

  const handleEditClick = (item) => {
    setShowUpdateUser(true);
    handleClick(item);
  };

  const UpdateUser = async(e) => {
    e.preventDefault();
    const url = BaseUrl() + "admin/update_user_by_admin";
    const data = {
      name:name,
      email:email,
      // password:password,
      mobile_number:mobile_number,
      company_Id  :company_Id,
      role:role,
      address:address,
      id:id
    }
    console.log(data)
    try{
      const res = await axios.post(url,data,auth)
      if(res.data.ResponseCode == 200){
        console.log("res",res)
        swal(t(`${res.data.ResponseMessage}`), {
          icon: "success",
          button:false
        });
        getAllUser();
        setShowUpdateUser(false)
      }else{
        swal(t(`${res.data.ResponseMessage}`))
      }

    }catch(error){
      console.log(error)
      // swal(error)
    }
  }

  const handleClick = (item) => {
    console.log("item",item)
    setName(item.name);
    setEmail(item.email);
    setMobileNumber(item.mobile_number);
    setComponyId(item.company_Id);
    setAddress(item.address);
    setRole(item.role);
    setId(item.id);
    setMobileNumber(item.mobile_number);
    setComponyId(item.company_Id);
  };



  const getAllUser = async () => {
    setLoading(true)
    const url = BaseUrl() + "get-all-user";
    try {
      const res = await axios.get(url, auth);
      if (res.data.ResponseCode == 200) {
        setData(res.data.getUser);
        setFilterData(res.data.getUser);
         setLoading(false)
      } 
    } catch (error) {
      console.log(error);
    }
  };

    const deleteUser = async (id) => {
      console.log("id",id)
    setId(id);
    swal({
      title: t("user.delete.warning.title"),
      text: t("user.delete.warning.text"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async(willDelete) => {
      
      if (willDelete) {
        const url = BaseUrl() + "admin/user/delete"
        const body = {
          user_id: id
        };
        const res = await axios.post(url, body, auth);
        getAllUser();
        swal(t("user.delete.warning.success"), {
          icon: "success",
          button:false
        });
      } else {
        // swal("Your imaginary file is safe!");
    }
     })
  }

  useEffect(() => {
    const auth = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    getAllUser();
  }, []);

  useEffect(() => {
    const result = data.filter((userlist) => {
      return userlist.name.toLowerCase().match(search.toLowerCase());
    });
    setFilterData(result);
  }, [search]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  
  return (
    <>
      <div >
        <Navbar />
      </div>

      {ShowUpdateUser && 
         <div className='popup-start'>
        <div
        ref={ref}
         >
          <form mathod="post" onSubmit={(e) => UpdateUser(e)}>
            <div className="add-button-and-search-container">
              <div
                onClick={() => setShowUpdateUser(false)}
                className="add-button-and-search-close-button"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  onClick={() => setShowUpdateUser(false)}
                  className="add-button-and-search-icon2"
                >
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>

              <div className="add-button-and-search-container1">
                <div className="add-button-and-search-comapany-details">
                  <span className="add-button-and-search-text1">
                  {t("user.updateUser")}
                  </span>
                </div>

                <div className="add-button-and-search-input-feilds">
                  <input
                    type="text"
                    required
                    autoFocus
                    placeholder={t("user.name")}
                    className="input"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <input
                    type="email"
                    required
                    autoFocus
                    placeholder="Email"
                    className="add-button-and-search-textinput2 input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    required
                    autoFocus
                    placeholder={t("user.address")}
                    className="add-button-and-search-textinput3 input"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    required
                    maxlength="10"
                    autoFocus
                    placeholder={t("user.phoneNumber")}
                    className="add-button-and-search-textinput4 input"
                    value={mobile_number}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    disabled
                    required
                    autoFocus
                    placeholder={t("user.userName")}
                    className="add-button-and-search-textinput5 input"
                    value={company_Id}
                    onChange={(e) => {
                      setComponyId(e.target.value);
                    }}
                  />
                  {/* <input
                    type="password"
                    required
                    autoFocus
                    placeholder={t("user.password")}
                    className="add-button-and-search-textinput6 input"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  /> */}
                  <select
                  className="selection-class"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                >
                  {/* <option value="" disabled>{t("user.selectRole")}</option> */}
                  {["admin", "user", "visitor"].map((roleOption) => (
                    <option key={roleOption} value={roleOption}>
                      {capitalizeFirstLetter(roleOption)}
                    </option>
                  ))}
                </select>
                </div>

                <div className="add-button-and-search-done-button"  onClick={ UpdateUser}>
                  <div className="add-button-and-search-container2">
                    <button className="add-button-and-search-text2"  onClick={UpdateUser}>
                    {t("user.update")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        </div>
      }

      <AddButtonAndSearch
        rootClassName="add-button-and-search-root-class-name"
        onClick={filterData}
      ></AddButtonAndSearch>

     {/* <div className="user-management-list-container">
       {loading ? (
     <Spinner className="mx-auto" animation="border" />
      ):(
     
        <div className="user-management-list">
          <div className="user-management-head-element">
            <div className="user-management-name-text">
              <span className="user-management-text11">Name</span>
            </div>
            <div className="user-management-email-text">
              <span className="user-management-text12">Email</span>
            </div>
            <div className="user-management-status-text">
              <span className="user-management-text13">Adresse</span>
            </div>
            <div className="user-management-status-text">
              <span className="user-management-text13">Status</span>
            </div>
            <div className="user-management-edit-icon">
              <svg viewBox="0 0 1024 1024" className="user-management-icon2">
                <path d="M469.333 128h-298.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-298.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v298.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h298.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM759.168 76.501l-405.333 405.333c-5.205 5.163-9.259 11.947-11.221 19.84l-42.667 170.667c-1.664 6.4-1.792 13.568 0 20.693 5.717 22.869 28.885 36.779 51.755 31.061l170.667-42.667c7.125-1.749 14.080-5.504 19.84-11.221l405.333-405.333c25.984-25.984 38.997-60.16 38.997-94.165s-13.013-68.181-38.997-94.165-60.203-39.040-94.208-39.040-68.181 13.013-94.165 38.997zM819.499 136.832c9.344-9.344 21.504-13.995 33.835-13.995s24.491 4.651 33.835 13.995 13.995 21.504 13.995 33.835-4.651 24.491-13.995 33.835l-396.971 396.971-90.197 22.571 22.571-90.197z"></path>
              </svg>
            </div>
            <div className="user-management-block-text">
              <span className="user-management-text14">Block</span>
            </div>
          </div>
           
          {filterData.map((item) => {
            return (
              <div className="user-management-head-element1">
                <div className="user-management-name-text1">
                  <span className="user-management-text15" maxlength={4}>{item.name.substring(0, 10)}</span>
                </div>
                <div className="user-management-email-text1">
                  <span className="user-management-text16">{item.email.substring(0, 15)}</span>
                </div>
                <div className="user-management-status-text1">
                  <span className="user-management-text17">
                    {item?.address}
                  </span>
                </div>
                <div className="user-management-status-text1">
                  <span className="user-management-text17">
                    {item.status == "0" ? "Active" : "Block"}
                  </span>
                </div>
                <div
                  className="user-management-edit-icon1"
                  onClick={() => {
                    setShowUpdateUser(true);
                    handleClick(item);
                  }}
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="user-management-icon4"
                    onClick={() => {
                      setShowUpdateUser(true);
                      handleClick(item);
                    }}
                  >
                    <path d="M469.333 128h-298.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-298.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v298.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h298.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM759.168 76.501l-405.333 405.333c-5.205 5.163-9.259 11.947-11.221 19.84l-42.667 170.667c-1.664 6.4-1.792 13.568 0 20.693 5.717 22.869 28.885 36.779 51.755 31.061l170.667-42.667c7.125-1.749 14.080-5.504 19.84-11.221l405.333-405.333c25.984-25.984 38.997-60.16 38.997-94.165s-13.013-68.181-38.997-94.165-60.203-39.040-94.208-39.040-68.181 13.013-94.165 38.997zM819.499 136.832c9.344-9.344 21.504-13.995 33.835-13.995s24.491 4.651 33.835 13.995 13.995 21.504 13.995 33.835-4.651 24.491-13.995 33.835l-396.971 396.971-90.197 22.571 22.571-90.197z"></path>
                  </svg>
                </div>
                <div className="user-management-block-text1">
                  <span className="user-management-text18">Block</span>
                </div>
              </div>
            );
          })}

        </div>
      )}
        
      </div>*/}

   <Col>
    <div className="shadow-lg  mb-5 py-3 bg-body rounded">
       <Table className="shadow-lg   bg-body rounded">
      <thead>
        <tr>
          <th>#</th>
          <th>{t("user.name")}</th>
          <th>Email</th>
          <th>{t("user.address")}</th>
          <th>{t("user.userName")}</th>
          <th>{t("user.phoneNumber")}</th>
          <th>{t("user.role")}</th>
          <th style={{ width: '130px' }}>{t("user.action")}</th>
        </tr>
      </thead>
      <tbody>
      {filterData.map((item) => {
        return(
        <tr key={item.id}>
          <td >{a++}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
          <td>{item?.address}</td>
          <td>{item?.company_Id}</td>
          <td>{item?.mobile_number}</td>
          <td>{item?.role}</td>
          <td className="action-cell" style={{ width: '130px', padding: '0.5rem' }}>
          {(item.id !== base64_decode(localStorage.getItem("userId")) && item.id !== "6419893797e0515dbbf0c700") && (
            <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-sm btn-outline-primary me-1" onClick={() => handleEditClick(item)}>
                {t("user.edit")}
              </button>
              <button className="btn btn-sm btn-outline-danger" onClick={() => deleteUser(item.id)}>
                {t("user.delete")}
              </button>
            </div>
          )}
        </td>
        </tr>
        )})}
      </tbody>
    </Table>
    </div>
       </Col>
    </>
  );
};

export default UserManagement;
