import React, { useState, useEffect, useRef } from "react";


import auth from "../Auth";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';
import Navbar from "../components/navbar";
import HelicopterIconBlack from "../components/helicopter-icon-black";
import HelicopterIconGreen from "../components/helicopter-icon-green";
import HelicopterIconRed from "../components/helicopter-icon-red";
import HelicopterIconYellow from "../components/helicopter-icon-yellow";
import HelicopterIconStack from "../components/helicopter-icon-stack";
import HelicopterIconStackHover from "../components/helicopter-icon-stack-hover";
import NotificationHover from "../components/notification-hover";
import HelpCenterPopUp from "../components/help-center-pop-up";
import ProfilePopUp from "../components/profile-pop-up";
import "./home.css";
import Heli from '../assest/HYla7Hv.png'
import useOnClickOutside from "../components/use-onclick-outside";
import { useTranslation } from "react-i18next";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import BaseUrl from "../BaseUrl";
import "mapbox-gl/dist/mapbox-gl.css";
import '../components/manage-helicopter-card.css'
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { getDistance } from 'geolib';
mapboxgl.accessToken = "pk.eyJ1IjoiZmhzaW50IiwiYSI6ImNsYWNleWl3dDAycTYzbnBjdzdsc2drNG0ifQ.SRAl1VhLNL3_DztwVBPZYQ";



const Home = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const filterOptionRef = useRef("Show all");
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(7.7275985);
  const [lat, setLat] = useState(46.4167148);
  const [zoom, setZoom] = useState(5);
  const [id, setID] = useState("")
  const [Einsatzbereit_in, setEinsatzbereit_in] = useState("");
  const currentFlyLoc = {};
  const markers = {};
  const helicopterCurrLoc = []
  const [helicopterData, setHelicopterData] = useState({});
  const [authRole, setAuthRole] = useState("");
  const { i18n, t } = useTranslation();
  const [fireMarkers, setFireMarkers] = useState([]);
  const [selectedFire, setSelectedFire] = useState(null);
  const selectedFireRef = useRef(null);
  const ref = useRef();

  useEffect(() => {
    console.log("Selected fire updated:", selectedFire);
    selectedFireRef.current = selectedFire;
  }, [selectedFire]);

  useEffect(() => {
    if (map.current) return; // initialize map only
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/fhsint/clacf8lg5000215qu1hphnlin",
      center: [lng, lat],
      // center: [2.335, 48.88],
      zoom: 6,
      minZoom: 2,
    });
  
    const userRole = base64_decode(localStorage.getItem("role"));
    if (userRole === 'admin' || userRole === 'user') {
      map.current.on('contextmenu', handleRightClick);
    }
  
    // Add this click event listener to the map
    map.current.on('click', (e) => {
      // Check if the click is on a helicopter marker or a cluster
      const features = map.current.queryRenderedFeatures(e.point, { 
        layers: ['unclustered-point', 'clusters'] 
      });
    
      if (features.length === 0 && selectedFireRef.current) {
        // If the click is not on a helicopter marker or a cluster, and there's a selected fire, deselect it
        selectedFireRef.current.getElement().classList.remove('selected-fire');
        setSelectedFire(null);
      } else if (features.length > 0 && features[0].layer.id === 'clusters') {
        // If the click is on a cluster, don't deselect the fire
        return;
      }
    });
  
    // apiCalling();
    setInterval(() => {
      apiCalling("update", false);
      fetchExistingFires();
    }, 1000 * 30);
  
  }, [helicopterData]);
  

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    // map.current.on('move', () => {
    fetchExistingFires();
    apiCalling("add", false);
    // });
    const role = localStorage.getItem("role");
    setAuthRole(base64_decode(role));
  }, []);


  useEffect(() => {
    
    apiCalling("add", false);
  }, []);

  const filterHelicopters = (geojson) => {
    if (filterOptionRef.current === "Show all") {
      return geojson;
    }

    if (filterOptionRef.current === "Hide all") {
      return {};
    }

    return Object.keys(geojson).reduce((filtered, key) => {
      const helicopter = geojson[key];
      switch (filterOptionRef.current) {
        case "15 Min":
          if (helicopter.Einsatzbereit_in === "1") {
            filtered[key] = helicopter;
          }
          break;
        case "30 Min":
          if (helicopter.Einsatzbereit_in <= "2") {
            filtered[key] = helicopter;
          }
          break;
        case "60 Min":
          if (helicopter.Einsatzbereit_in <= "3") {
            filtered[key] = helicopter;
          }
          break;
        case "2 Std":
          if (helicopter.Einsatzbereit_in <= "4") {
            filtered[key] = helicopter;
          }
          break;
        case "3 Std":
          if (helicopter.Einsatzbereit_in <= "5") {
            filtered[key] = helicopter;
          }
          break;
        case "Im Einsatz":
          if (helicopter.Einsatzbereit_in === "6") {
            filtered[key] = helicopter;
          }
          break;
        case "Abgemeldet N/A":
          if (helicopter.Einsatzbereit_in === "7") {
            filtered[key] = helicopter;
          }
          break;
        case "Im Betrieb":
          if (helicopter.Einsatzbereit_in === "8") {
            filtered[key] = helicopter;
          }
          break;
        default:
          break;
      }
      return filtered;
    }, {});
  }


  const apiCalling = (type = "add", showLoading = false) => {
    if (showLoading) {
      setIsLoading(true);
    }

    const token = localStorage.getItem("authorization");
    var config = {
      method: 'post',
      url: BaseUrl() + "get-helicopter-data",
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    return axios(config, {})
      .then(function (response) {
        var geojson = response.data.getHelicopter;
        console.log("Updated helicopters: ", response.data.getHelicopter)
        const filteredGeojson = filterHelicopters(geojson);

        while (helicopterCurrLoc.length > 0) {
          helicopterCurrLoc.pop();
        }

        if (Object.keys(filteredGeojson).length > 0) {
          for (var key in filteredGeojson) {
            if (map.current.getSource('source-' + key)) {
              map.current.getSource('source-' + key).setData({
                'type': 'Feature',
                'properties': {
                  'color': "#1c3351"
                },
                'geometry': {
                  'type': 'LineString',
                  'coordinates': filteredGeojson[key]['tempGeoLine']
                }
              });

              var lastLength = filteredGeojson[key]['tempGeoLine'].length - 1;
              filteredGeojson[key]['coordinates'] = filteredGeojson[key]['tempGeoLine'][lastLength];
              filteredGeojson[key]['clusterTitle'] = filteredGeojson[key]['title'] + ",";
              helicopterCurrLoc.push({
                type: "Feature",
                properties: filteredGeojson[key],
                geometry: {
                  coordinates: filteredGeojson[key]['tempGeoLine'][lastLength],
                  type: "Point"
                },
                id: key
              })
            } else {
              map.current.addSource('source-' + key, {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'properties': {
                    'color': "#1c3351"
                  },
                  'geometry': {
                    'type': 'LineString',
                    'coordinates': filteredGeojson[key]['tempGeoLine']
                  }
                }
              })

              map.current.addLayer({
                'id': 'lines-' + key,
                'type': 'line',
                'source': 'source-' + key,
                'layout': {
                  'line-join': 'round',
                  'line-cap': 'round',
                  'visibility': 'none'
                },
                'paint': {
                  'line-color': ['get', 'color'],
                  'line-width': 5
                }
              });

              var lastLength = filteredGeojson[key]['tempGeoLine'].length - 1;
              filteredGeojson[key]['coordinates'] = filteredGeojson[key]['tempGeoLine'][lastLength];
              filteredGeojson[key]['clusterTitle'] = filteredGeojson[key]['title']+",";
              helicopterCurrLoc.push({
                type: "Feature",
                properties: filteredGeojson[key],
                geometry: {
                  coordinates: filteredGeojson[key]['tempGeoLine'][lastLength],
                  type: "Point"
                },
                id: key
              })
            }
          }
        }

        if (!map.current.getSource('halicopters')) {
          map.current.addSource("halicopters", {
            type: "geojson",
            data: { features: helicopterCurrLoc },
            cluster: true,
            clusterMaxZoom: 17,
            clusterRadius: 60,
            clusterProperties: {
              title: ["concat", ['get', 'clusterTitle']]
            }
          });

          map.current.addLayer({
            id: "clusters",
            type: "circle",
            source: "halicopters",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": "#51bbd6",
              "circle-radius": 20
            }
          });

          map.current.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "halicopters",
            filter: ["has", "point_count"],
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 12
            }
          });

          map.current.addLayer({
            id: "unclustered-point",
            type: "symbol",
            source: "halicopters",
            filter: ["!", ["has", "point_count"]]
          });

          let clickTimer = null;
          let singleClickTimeout = 250;
          
          map.current.on("click", "clusters", function (e) {
            if (clickTimer === null) {
              clickTimer = setTimeout(() => {
                clickTimer = null;
                // Single click action
                var features = map.current.queryRenderedFeatures(e.point, { layers: ["clusters"] });
                var clusterId = features[0].properties.cluster_id;
                map.current.getSource("halicopters").getClusterLeaves(clusterId, 100, 0, function(err, leaves) {
                  if (err) {
                    console.error('Error getting cluster leaves:', err);
                    return;
                  }
              
                  // Create clickable links for each helicopter
                  const helicopterLinks = leaves.map(leaf => 
                    `<a href="#" class="helicopter-link" data-id="${leaf.properties.id}" data-track-id="${leaf.properties.trackId}">${leaf.properties.title}</a>`
                  ).join('');
              
                  // Create a popup
                  const popup = new mapboxgl.Popup()
                    .setLngLat(features[0].geometry.coordinates)
                    .setHTML(`<div class="popup-content">${helicopterLinks}</div>`)
                    .addTo(map.current);
          
                  // Add click event listeners to the helicopter links
                  popup.getElement().querySelectorAll('.helicopter-link').forEach(link => {
                    link.addEventListener('click', function(e) {
                      e.preventDefault();
                      const helicopterId = this.getAttribute('data-id');
                      const trackId = this.getAttribute('data-track-id');
                      console.log('Clicked helicopter ID:', helicopterId, 'Track ID:', trackId);
                      
                      // Find the helicopter data in the leaves array
                      const helicopterData = leaves.find(leaf => leaf.properties.id === helicopterId);
                      
                      if (helicopterData) {
                        // Ensure trackId is included in the data
                        helicopterData.properties.trackId = trackId;
                        // Call toggleSidebar with the helicopter data
                        toggleSidebar(null, helicopterData.properties);
                      } else {
                        console.error('Could not find data for helicopter:', helicopterId);
                      }
                    });
                  });
                });
              }, singleClickTimeout);
            } else {
              clearTimeout(clickTimer);
              clickTimer = null;
              // Double click action
              var features = map.current.queryRenderedFeatures(e.point, { layers: ["clusters"] });
              var clusterId = features[0].properties.cluster_id;
              map.current
                .getSource("halicopters")
                .getClusterExpansionZoom(clusterId, function (err, zoom) {
                  if (err) return;
          
                  const increasedZoom = zoom + 2;
          
                  map.current.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: increasedZoom
                  });
                });
            }
          });

          map.current.on("mouseenter", "clusters", function () {
            map.current.getCanvas().style.cursor = "pointer";
          });
          map.current.on("mouseleave", "clusters", function () {
            map.current.getCanvas().style.cursor = "";
          });

          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
          });

          // map.current.on('mouseenter', 'cluster-count', (e) => {
          //   map.current.getCanvas().style.cursor = 'pointer';

          //   const coordinates = e.features[0].geometry.coordinates.slice();
          //   const description = e.features[0].properties.title;

          //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          //   }

          //   popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
          // });

          // map.current.on('mouseleave', 'cluster-count', () => {
          //   map.current.getCanvas().style.cursor = '';
          //   popup.remove();
          // });

          map.current.on('render', () => {
            if (!map.current.getSource('halicopters')) return;
            updateMarkers()
          });
        } else {
          map.current.getSource("halicopters").setData({ features: helicopterCurrLoc });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        if (showLoading) {
          setIsLoading(false);
        }
      });
  }

  const updateMarkers = () => {
    const features = map.current.querySourceFeatures('halicopters');
    const tempMarker = [];
    const featureId = []
    for (const feature of features) {
      if(featureId.indexOf(feature.id) > -1){
        continue;
      }
      if (feature.properties.cluster) {
        featureId.push(feature.id)
        continue;
      }
      featureId.push(feature.id)
      const coordinates = JSON.parse(feature.properties.coordinates)
      if (!markers[feature.id]) {
        tempMarker.push(feature.id)
        const el = document.createElement('div');
        el.id = feature.id;
        el.setAttribute('data-value', JSON.stringify(feature.properties))
        el.className = "helicopter-marker status" + feature.properties['Einsatzbereit_in'];
        el.innerHTML = '<p class="helicopter-icon-red-hover-text">' + feature.properties['title'] + '</p>' +
          '<p class="helicopter-icon-red-hover-text helicopter-status-text" id="hali_status_' + feature.id + '">' + 
          (feature.properties.Einsatzbereit_in == "1" ? "15 Min" : 
          feature.properties.Einsatzbereit_in == "2" ? "30 Min" : 
          feature.properties.Einsatzbereit_in == "3" ? "60 Min" : 
          feature.properties.Einsatzbereit_in == "4" ? "2 Std" : 
          feature.properties.Einsatzbereit_in == "5" ? "3 Std" : 
          feature.properties.Einsatzbereit_in == "6" ? "Im Einsatz" : 
          feature.properties.Einsatzbereit_in == "7" ? "Abgemeldet N/A" : 
          feature.properties.Einsatzbereit_in == "8" ? "Im Betrieb" : "") + 
          '</p><img alt="heli1" src="' + feature.properties.icon + '" class="helicopter-icon-green-image"/>';         
        currentFlyLoc[feature.id] = JSON.parse(feature.properties.coordinates)
        markers[feature.id] = new mapboxgl.Marker({element:el}).setLngLat(JSON.parse(feature.properties.coordinates)).addTo(map.current);
        el.addEventListener('click', (e) => toggleSidebar(e));
      } else {
        tempMarker.push(feature.id)
        if(currentFlyLoc[feature.id] && currentFlyLoc[feature.id][0] && currentFlyLoc[feature.id][0] !== coordinates[0]){
          markers[feature.id].setLngLat(feature.geometry.coordinates);
        }
      }
    }

    for(const markKey in markers){
      if(tempMarker && tempMarker.indexOf(parseInt(markKey)) > -1){
      }else{
        markers[markKey].remove();
        delete markers[markKey];
      }
    }
  }

  const updateHelicopterStatus = async (value, row) => {
    setEinsatzbereit_in(row.HeliStatus);
    setID(row.id);
    const url = BaseUrl() + "admin/update-status_helicopter"
    const data = {
      Einsatzbereit_in: value,
      id: row.id
    }
    const token = localStorage.getItem("authorization");
    const auth1 = {
      headers: {
        authorization: `bearer ${token}`,
      },
    }
    try {
      const res = await axios.post(url, data, auth1)
      if (res.data.ResponseCode == 200) {


        var markerObj = document.getElementById(helicopterData.trackId);
        markerObj.classList.remove('status1');
        markerObj.classList.remove('status2');
        markerObj.classList.remove('status3');
        markerObj.classList.remove('status4');
        markerObj.classList.remove('status5');
        markerObj.classList.remove('status6');
        markerObj.classList.remove('status7');
        markerObj.classList.remove('status8');
        markerObj.classList.add('status' + value);

        var selectObj = document.getElementById("select_box");
        selectObj.classList.remove('status1');
        selectObj.classList.remove('status2');
        selectObj.classList.remove('status3');
        selectObj.classList.remove('status4');
        selectObj.classList.remove('status5');
        selectObj.classList.remove('status6');
        selectObj.classList.remove('status7');
        selectObj.classList.remove('status8');
        selectObj.classList.add('status' + value);

        document.getElementById("hali_status_" + helicopterData.trackId).innerHTML = 
          value == "1" ? "15 Min" :
          value == "2" ? "30 Min" :
          value == "3" ? "60 Min" :
          value == "4" ? "2 Std" :
          value == "5" ? "3 Std" :
          value == "6" ? "Im Einsatz" :
          value == "7" ? "Abgemeldet N/A" :
          value == "8" ? "Im Betrieb" : "";
        apiCalling();
         swal(t("helicopter.update.success"), {
          button:false,
          icon: "success",
        });
       
      }


    } catch (error) {
      // swal(error)
      console.log(error)
    }
  }

  const handleVisibilityToggle = async (id) => {
    const currentVisibility = helicopterData.visibility !== false;
    const newVisibility = !currentVisibility;
    const url = BaseUrl() + "admin/update-visibility-helicopter";
    const data = {
      id: id,
      visibility: newVisibility
    };
    const token = localStorage.getItem("authorization");
    const auth = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
  
    try {
      const res = await axios.post(url, data, auth);
      if (res.data.ResponseCode === 200) {
        // Update the local state immediately

        if(newVisibility === false){

          setHelicopterData(prevData => ({
            ...prevData,
            Einsatzbereit_in: "7",
            visibility: newVisibility
          }));
    
          var markerObj = document.getElementById(helicopterData.trackId);
          markerObj.classList.remove('status1', 'status2', 'status3', 'status4', 'status5', 'status6', 'status7', 'status8');
          markerObj.classList.add('status7');
  
          var selectObj = document.getElementById("select_box");
          selectObj.classList.remove('status1', 'status2', 'status3', 'status4', 'status5', 'status6', 'status7', 'status8');
          selectObj.classList.add('status7');
          selectObj.value = "7"; // Set the select value to "7"
  
          document.getElementById("hali_status_" + helicopterData.trackId).innerHTML = "Abgemeldet N/A";
        }

        setHelicopterData(prevData => ({
          ...prevData,
          visibility: newVisibility
        }));
  
        // Update the marker's data attribute
        const markerElement = document.getElementById(helicopterData.trackId);
        if (markerElement) {
          const markerData = JSON.parse(markerElement.getAttribute('data-value'));
          markerData.visibility = newVisibility;
          markerElement.setAttribute('data-value', JSON.stringify(markerData));
        }

    
        apiCalling();
         swal(t("helicopter.update.success"), {
          button:false,
          icon: "success",
        });
       
      }
  
        // Call apiCalling to update all data
        apiCalling();
  
        swal(t("helicopter.visibility.update.success"), {
          button: false,
          icon: "success",
        });
    } catch (error) {
      console.error("Error updating helicopter visibility:", error);
      swal(t("helicopter.visibility.update.error"), {
        button: false,
        icon: "error",
      });
    }
  };

  const toggleSidebar = (e, passedHelicopterData = null) => {
    console.log("toggle sidebar triggered");
    console.log("This is the selected fire: ", selectedFireRef.current);
  
    let data;
    if (passedHelicopterData) {
      // If we're passed helicopter data directly, use that
      data = passedHelicopterData;
    } else if (e && e.target) {
      // If we have an event with a target, try to get data from the marker element
      const targetElement = e.target.closest('.helicopter-marker');
      if (!targetElement || !targetElement.hasAttribute('data-value')) {
        console.error('Invalid target element for toggleSidebar');
        return;
      }
      data = JSON.parse(targetElement.getAttribute('data-value'));
    } else {
      console.error('No valid data source for toggleSidebar');
      return;
    }
  
    console.log("Helicopter data:", data);

    if (selectedFireRef.current) {
      const fireCoords = selectedFireRef.current.getLngLat();
      // const data = JSON.parse(e.target.closest('.helicopter-marker').getAttribute('data-value'));
      const heliCoords = Array.isArray(data.coordinates) ? data.coordinates : JSON.parse(data.coordinates);
      console.log("These are the fireCords: ", fireCoords);
      console.log("These are the heliCoords: ", heliCoords);
  
      console.log("Fire latitude:", fireCoords.lat, "Fire longitude:", fireCoords.lng);
      console.log("Heli latitude:", heliCoords[1], "Heli longitude:", heliCoords[0]);
  
      const distance = getDistance(
        { latitude: fireCoords.lat, longitude: fireCoords.lng },
        { latitude: heliCoords[1], longitude: heliCoords[0] }
      );
  
      console.log("This is the distance: ", distance);
  
      if (isNaN(distance)) {
        console.error("Failed to calculate distance. Check coordinate formats.");
        swal({
          title: "Error",
          text: "Unable to calculate distance. Please try again.",
          icon: "error",
        });
      } else {

        map.current.addSource('flightPath', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'LineString',
              'coordinates': [
                [heliCoords[0], heliCoords[1]],
                [fireCoords.lng, fireCoords.lat]
              ]
            }
          }
        });
  
        map.current.addLayer({
          'id': 'flightPathLayer',
          'type': 'line',
          'source': 'flightPath',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-color': '#1c3351',
            'line-width': 2
          }
        });
  
        const distanceKm = distance / 1000;
        const flightTimeHours = distanceKm / 200; // 200 km/h speed
        const flightTimeMinutes = Math.round(flightTimeHours * 60);
        const flightTimeHoursRounded = Math.floor(flightTimeMinutes / 60);
        const flightTimeMinutesRemainder = flightTimeMinutes % 60;
  
        // Get helicopter availability time in minutes
        let availabilityMinutes = 0;
        let availabilityStatus = "";
        switch (data.Einsatzbereit_in) {
          case "1": availabilityMinutes = 15; availabilityStatus = "15 Min"; break;
          case "2": availabilityMinutes = 30; availabilityStatus = "30 Min"; break;
          case "3": availabilityMinutes = 60; availabilityStatus = "60 Min"; break;
          case "4": availabilityMinutes = 120; availabilityStatus = "2 Std"; break;
          case "5": availabilityMinutes = 180; availabilityStatus = "3 Std"; break;
          case "6": availabilityStatus = "Im Einsatz"; break;
          case "7": availabilityStatus = "Abgemeldet N/A"; break;
          case "8": availabilityStatus = "Im Betrieb"; break;
          default: availabilityStatus = "Unknown";
        }
  
        let alertText = `Distance to fire: ${distanceKm.toFixed(2)} km\n\n` +
                        `Flight time: ${flightTimeHoursRounded} hours and ${flightTimeMinutesRemainder} minutes\n\n`
  
        if (["6", "7", "8"].includes(data.Einsatzbereit_in)) {
          alertText += `Availability time: Unknown\n\n` +
                       `Total estimated time: Unknown`;
        } else {
          const totalMinutes = flightTimeMinutes + availabilityMinutes;
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          alertText += `Availability time: ${availabilityMinutes} minutes\n\n` +
                       `Total estimated time: ${hours} hours and ${minutes} minutes`;
        }
  
        // Display distance and estimated time to the user
        swal({
          title: "Distance and Time",
          text: alertText,
          icon: "info",
        }).then(() => {
          // Remove the flight path when the popup is closed
          if (map.current.getSource('flightPath')) {
            map.current.removeLayer('flightPathLayer');
            map.current.removeSource('flightPath');
          }
        });

      }
    } else {
      // var data = JSON.parse(e.target.closest('.helicopter-marker').getAttribute('data-value'))
      var trackId = data.trackId || data.id;  // Use trackId if available, otherwise fall back to id
      console.log("Track ID:", trackId);  // Add this line for debugging
      const elem = document.getElementById("left");

      if (!trackId) {
        console.error('No valid trackId found for helicopter');
        return;
      }

      const currentTrackId = document.getElementById('trackId').getAttribute('data-trackid');

      if(currentTrackId != trackId){
        setHelicopterData(data)
        console.log("This is the set data from toggle: ", data)
        
        if(currentTrackId) map.current.setLayoutProperty('lines-'+currentTrackId, 'visibility', 'none');
      
        map.current.setLayoutProperty(
          'lines-'+trackId,
          'visibility',
          'visible'
        );

        elem.classList.remove('collapsed')
      }else {
        const collapsed = elem.classList.toggle('collapsed');
  
        if (map.current.getLayer('lines-'+trackId)) {
          const visibility = map.current.getLayoutProperty(
            'lines-'+trackId,
            'visibility'
          );
            
          if (visibility === 'visible') {
            map.current.setLayoutProperty('lines-'+trackId, 'visibility', 'none');
          } else {
            map.current.setLayoutProperty(
              'lines-'+trackId,
              'visibility',
              'visible'
            );
          }
        } else {
          console.warn(`Layer 'lines-${trackId}' does not exist in the map`);
        }
      }
    }
    
  }



  const closePopup = (id) => {
    map.current.setLayoutProperty('lines-'+id, 'visibility', 'none');
    const elem = document.getElementById("left");
    const collapsed = elem.classList.toggle('collapsed');
  }

  const handleRightClick = (e) => {
    e.preventDefault();
    const { lng, lat } = e.lngLat;
    
    console.log("Right-click detected at:", lng, lat);

    const features = map.current.queryRenderedFeatures(e.point, { layers: ['fire-markers-layer'] });
    if (features.length > 0) {
      console.log("Right-click on existing fire marker, ignoring");
      return;
    }
  
    // Create a popup for the context menu without a close button
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true
    })
      .setLngLat([lng, lat])
      .setHTML('<button id="report-fire-btn" class="report-fire-btn">Report a fire</button>')
      .addTo(map.current);
  
    // Add click event listener to the "Report a fire" button
    document.getElementById('report-fire-btn').addEventListener('click', async () => {
      console.log("Report fire button clicked");
      const { fireId, createdBy } = await createFireInDatabase(lng, lat);
      addFireMarker(lng, lat, fireId, createdBy);
      popup.remove();
    });
  };

  const addFireMarker = async (lng, lat, existingFireId = null, createdBy = null) => {
    try {
      let fireId = existingFireId || await createFireInDatabase(lng, lat);
      
      // Check if the marker already exists
      const existingMarker = document.querySelector(`.fire-marker[data-fire-id="${fireId}"]`);
      if (existingMarker) {
        console.log(`Fire marker ${fireId} already exists`);
        return;
      }
  
      const el = createFireIcon();
      el.setAttribute('data-fire-id', fireId);
      if (createdBy) {
        el.setAttribute('data-created-by', createdBy);
      }
      
      const newFireMarker = new mapboxgl.Marker({
        element: el,
        anchor: 'center',
      })
        .setLngLat([lng, lat])
        .addTo(map.current);
  
      el.addEventListener('click', (event) => {
        event.stopPropagation();
        handleFireClick(newFireMarker, event);
      });
  
      const userRole = base64_decode(localStorage.getItem("role"));
      const userId = base64_decode(localStorage.getItem("userId"));
  
      if (userRole === "admin" || (userRole === "user" && createdBy === userId)) {
        const deleteBtn = document.createElement('div');
        deleteBtn.className = 'delete-fire-btn';
        deleteBtn.innerHTML = '×';
        deleteBtn.addEventListener('click', (e) => {
          e.stopPropagation();
          deleteFireMarker(newFireMarker);
        });
  
        setTimeout(() => {
          const markerElement = newFireMarker.getElement();
          markerElement.appendChild(deleteBtn);
        }, 0);
      }
  
    } catch (error) {
      console.error("Failed to add fire marker:", error);
      swal("Error", "Failed to add fire marker. Please try again.", "error");
    }
  };
  
  const handleFireClick = (fireMarker, event) => {

    event.stopPropagation();

    console.log("Fire is clicked");
    if (selectedFireRef.current === fireMarker) {
      // Deselect if clicking the same fire
      setSelectedFire(null);
      fireMarker.getElement().classList.remove('selected-fire');
    } else {
      // Select the new fire
      if (selectedFireRef.current) {
        selectedFireRef.current.getElement().classList.remove('selected-fire');
      }
      setSelectedFire(fireMarker);
      fireMarker.getElement().classList.add('selected-fire');
    }
  };

  
  const createFireIcon = () => {
    console.log("Fire icon created")
    const el = document.createElement('div');
    el.className = 'fire-marker';
    el.style.backgroundImage = 'url(/favicone.ico)';
    el.style.width = '45px';
    el.style.height = '45px';
    el.style.backgroundSize = 'contain';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundPosition = 'center';
    return el;
  };

  const deleteFireMarker = async (fireMarker) => {
    try {
      const fireId = fireMarker.getElement().getAttribute('data-fire-id');
      const createdBy = fireMarker.getElement().getAttribute('data-created-by');
      const userRole = base64_decode(localStorage.getItem("role"));
      const userId = base64_decode(localStorage.getItem("userId"));
  
      if (userRole === "admin" || (userRole === "user" && createdBy === userId)) {
        await deleteFireFromDatabase(fireId);
        fireMarker.remove();
      } else {
        swal("Error", "You don't have permission to delete this fire.", "error");
      }
    } catch (error) {
      console.error("Failed to delete fire marker:", error);
      swal("Error", "Failed to delete fire marker. Please try again.", "error");
    }
  };


  const createFireInDatabase = async (lng, lat) => {
    const token = localStorage.getItem("authorization");
    const userId = base64_decode(localStorage.getItem("userId"))
    const config = {
      method: 'post',
      url: BaseUrl() + "create-fire",
      headers: {
        authorization: `bearer ${token}`,
      },
      data: {
        latitude: lat,
        longitude: lng,
        status: 'active',
        createdBy: userId
      }
    };
  
    try {
      const response = await axios(config);
      return {
        fireId: response.data.fireId,
        createdBy: userId
      };
    } catch (error) {
      console.error("Error creating fire in database:", error);
      throw error;
    }
  };

  const fetchExistingFires = async () => {
    const token = localStorage.getItem("authorization");
    const config = {
      method: 'get',
      url: BaseUrl() + "get-fires",
      headers: {
        authorization: `bearer ${token}`,
      }
    };
  
    try {
      const response = await axios(config);
      const incomingFires = response.data.fires;
  
      // Get all existing fire markers from the DOM
      const existingFireMarkers = document.querySelectorAll('.fire-marker');
      const existingFireIds = new Set(Array.from(existingFireMarkers).map(marker => marker.getAttribute('data-fire-id')));
  
      // Add new fires
      console.log("These are the incoming fires: ", incomingFires)
      incomingFires.forEach(fire => {
        if (!existingFireIds.has(fire.id.toString())) {
          addFireMarker(fire.longitude, fire.latitude, fire.id, fire.createdBy);
        }
      });
  
      // Remove fire markers that are no longer in the incoming data
      const incomingFireIds = new Set(incomingFires.map(fire => fire.id.toString()));
      existingFireMarkers.forEach(markerElement => {
        const fireId = markerElement.getAttribute('data-fire-id');
        if (!incomingFireIds.has(fireId)) {
          // Find the mapboxgl marker associated with this DOM element
          const marker = map.current.getCanvasContainer().querySelector(`[data-fire-id="${fireId}"]`);
          if (marker) {
            new mapboxgl.Marker(marker).remove();
          }
        }
      });
  
    } catch (error) {
      console.error("Error fetching existing fires:", error);
    }
  };

  const deleteFireFromDatabase = async (fireId) => {
    const token = localStorage.getItem("authorization");
    const config = {
      method: 'post',
      url: BaseUrl() + "delete-fire",
      headers: {
        authorization: `bearer ${token}`,
      },
      data: {
        fireId: fireId
      }
    };
  
    try {
      await axios(config);
    } catch (error) {
      console.error("Error deleting fire from database:", error);
      throw error;
    }
  };

  return (
    <div className="home-container">
      <Helmet>
        <meta property="og:title" content="Home" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <div className="home-map2">
        {isLoading && <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>}
        <div className="filter-dropdown">
        <select 
            value={filterOptionRef.current} 
            onChange={(e) => {
              filterOptionRef.current = e.target.value;
              apiCalling("update", true);
            }}
            className="filter-select"
          >
            <option value="Show all">Show all</option>
            <option value="Hide all">Hide all</option>
            <option value="15 Min">15 Min</option>
            <option value="30 Min">30 Min</option>
            <option value="60 Min">60 Min</option>
            <option value="2 Std">2 Std</option>
            <option value="3 Std">3 Std</option>
            <option value="Im Einsatz">Im Einsatz</option>
            <option value="Abgemeldet N/A">Abgemeldet N/A</option>
            <option value="Im Betrieb">Im Betrieb</option>
          </select>
        </div>
        <div id="left" className="sidebar flex-center left collapsed" >
          <div className="sidebar-content rounded-rect flex-center">

            <div className="manage-helicopter-card-container manage-helicopter-card-root-class-name">
              <img alt="image" src={helicopterData.image} className="manage-helicopter-card-image" />
              <div className="manage-helicopter-card-content2">
                <div className="manage-helicopter-card-container01">
                  <div className="manage-helicopter-card-container02">
                    <div className="manage-helicopter-card-container03"><span className="manage-helicopter-card-text">{helicopterData && helicopterData.heliId}</span><span className="manage-helicopter-card-text01">{helicopterData && helicopterData.title}</span></div>
                    <div className="manage-helicopter-card-einsatzbereit-in">
                      <span className="manage-helicopter-card-text02">{t("helicopter.ready")}:</span>
                      {(authRole === "admin" || (authRole === "user" && helicopterData.hasAccess)) ? (
                        <select 
                          id="select_box" 
                          onFocus={e => e.target.size = 8} 
                          onBlur={e => e.target.size = 1} 
                          className={`select-option-status status${helicopterData.Einsatzbereit_in}`}
                          onChange={(e) => {
                            e.target.size = 1
                            updateHelicopterStatus(e.target.value, helicopterData)
                          }}
                        >
                          <option className="select-option-status" hidden value="">
                            {helicopterData.Einsatzbereit_in == "1" ? "15 Min" :
                            helicopterData.Einsatzbereit_in == "2" ? "30 Min" :
                            helicopterData.Einsatzbereit_in == "3" ? "60 Min" :
                            helicopterData.Einsatzbereit_in == "4" ? "2 Std" :
                            helicopterData.Einsatzbereit_in == "5" ? "3 Std" :
                            helicopterData.Einsatzbereit_in == "6" ? "Im Einsatz" :
                            helicopterData.Einsatzbereit_in == "7" ? "Abgemeldet N/A" :
                            helicopterData.Einsatzbereit_in == "8" ? "Im Betrieb" : ""}
                          </option>
                          <option className="select-option-status1" value="1">15 Min</option>
                          <option className="select-option-status2" value="2">30 Min</option>
                          <option className="select-option-status3" value="3">60 Min</option>
                          <option className="select-option-status4" value="4">2 Std</option>
                          <option className="select-option-status5" value="5">3 Std</option>
                          <option className="select-option-status6" value="6">Im Einsatz</option>
                          <option className="select-option-status7" value="7">Abgemeldet N/A</option>
                          <option className="select-option-status8" value="8">Im Betrieb</option>
                        </select>
                      ) : (
                        <span className={`text-center select-option-status status${helicopterData.Einsatzbereit_in}`}>
                          {helicopterData.Einsatzbereit_in == "1" ? "15 Min" :
                          helicopterData.Einsatzbereit_in == "2" ? "30 Min" :
                          helicopterData.Einsatzbereit_in == "3" ? "60 Min" :
                          helicopterData.Einsatzbereit_in == "4" ? "2 Std" :
                          helicopterData.Einsatzbereit_in == "5" ? "3 Std" :
                          helicopterData.Einsatzbereit_in == "6" ? "Im Einsatz" :
                          helicopterData.Einsatzbereit_in == "7" ? "Abgemeldet N/A" :
                          helicopterData.Einsatzbereit_in == "8" ? "Im Betrieb" : ""}
                        </span>
                      )}

                    </div>
                  </div>
                  <div className="manage-helicopter-card-container13">
                    <span className="manage-helicopter-card-text">{t("helicopter.liveURL")}:</span>
                    <span className="manage-helicopter-card-text12">{helicopterData && helicopterData.LiveFeedURL ? helicopterData.LiveFeedURL : '-'}</span>
                  </div>
                  {authRole === "admin" && (
                    <div className="manage-helicopter-card-container14">
                      <span className="manage-helicopter-card-text">{t("helicopter.visibility")}:</span>
                      <div className="toggle-switch">
                        <input
                          type="checkbox"
                          id="visibility-toggle"
                          className="toggle-switch-checkbox"
                          checked={helicopterData.visibility !== false}
                          onChange={() => handleVisibilityToggle(helicopterData.id)}
                        />
                        <label className="toggle-switch-label" htmlFor="visibility-toggle">
                          <span className="toggle-switch-inner"></span>
                          <span className="toggle-switch-switch"></span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="manage-helicopter-card-container04">
                  <div className="manage-helicopter-card-container05">
                    <div className="manage-helicopter-card-container06">
                      <span className="manage-helicopter-card-text03">{t("helicopter.operator")}:</span><span className="manage-helicopter-card-text04">{helicopterData && helicopterData.Betreiber}</span>
                      <span className="manage-helicopter-card-text04" style={{"min-height": "auto"}}><span className="manage-helicopter-card-text03">{t("helicopter.number")}: <br></br></span>{helicopterData.number}</span>
                    </div>
                    <div className="manage-helicopter-card-container07"><span className="manage-helicopter-card-text05">{t("helicopter.watertank")}:</span><span className="manage-helicopter-card-text06">{helicopterData && helicopterData.Wassertank}</span></div>
                  </div>
                  <div className="manage-helicopter-card-container08">
                    <div className="manage-helicopter-card-container09">
                      <span className="manage-helicopter-card-text07"><span className="">{t("helicopter.capacity")}:</span><span className="manage-helicopter-card-text09"></span></span><span className="manage-helicopter-card-text10">{helicopterData && helicopterData.Fassungsvermogen}</span>
                    </div>
                    <div className="manage-helicopter-card-container10"><span className="manage-helicopter-card-text11">{t("helicopter.type")}:</span><span className="manage-helicopter-card-text12">{helicopterData && helicopterData.type}</span></div>
                  </div>
                </div>
              </div>

            </div>
            <div
              id="trackId"
              onClick={e=>closePopup(helicopterData.trackId)}
              className="add-helicopter-container-close-button"
              data-trackid={helicopterData.trackId}
            >
              <svg
                viewBox="0 0 1024 1024"
                className="add-helicopter-container-icon"
              >
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>
    </div>
  );
};

export default Home;
